import { createElement } from "~/foundation/Dom/createElement";

const createMarkup = (
    option,
    field,
    texts,
    classes,
    errorMessageTargetClass,
    useSvgBackground,
    alignment,
    bottomSvgIcon,
    textBackgroundColorHex
) => {

    let backgroundHex = textBackgroundColorHex;

    if (useSvgBackground && option.iconSvgContent) {
        const dummyElement = createElement("div", {
            html: option.iconSvgContent
        });
        const fillRect = dummyElement.querySelector("rect");
        let fillValue = undefined;

        if (fillRect) {
            fillValue = fillRect.getAttribute("fill") || fillRect.style.fill;
        }

        if (
            fillValue &&
            (fillValue.indexOf("#") !== -1 || fillValue.indexOf("rgb") !== -1)
        ) {
            backgroundHex = fillValue;
        }
    }

    const imageTagElement = `<div style="min-height: 318px;"><img src="${option.iconUrl}" /></div>`;

    return `
            <div class="gallery-cell">
                <div class="${classes.cardClass} ${
        option.theme ? `greeting-card--${option.theme}` : ""
    }" style="background: ${backgroundHex}">
                    <input type="radio"
                        id="${field.key}-${option.label}"
                        name="${field.key}"
                        value="${option.value}"
                        data-error-message="${field.defaultErrorMessage}"
                        data-error-container="${errorMessageTargetClass}"
                        ${option.selected ? ` checked` : ``}
                        ${field.readOnly ? ` readonly` : ``}
                        ${field.isRequired ? ` required` : ``}
                    />
                    <label for="${field.key}-${option.label}" class="${
        classes.cardClass
    }__label" aria-label="${option.label}">
                    ${option.iconSvgContent ? option.iconSvgContent : imageTagElement}
                    </label>
                    <div class="${classes.cardClass}__greeting ${(bottomSvgIcon && bottomSvgIcon !== "") && `${classes.cardClass}__greeting--has-bottom-icon`}">
                        <p class="${classes.cardClass}__default-message"></p>
                        <p class="${classes.cardClass}__author"></p>
                    </div>
                    ${(bottomSvgIcon && bottomSvgIcon !== "") ?
                        `<div class="${classes.cardClass}__bottom-icon" style="justify-content:
                            ${
                                alignment === "right"
                                    ? "flex-end"
                                    : alignment === "center"
                                        ? "center"
                                        : alignment === "left"
                                            ? "flex-start"
                                            : "flex-end"
                            };">
                            ${bottomSvgIcon}
                        </div>`
                        : ""
                    }
                </div>
            </div>
    `;
};

export const buildRadioIconInput = (
    field,
    texts,
    classes,
    errorMessageTargetClass,
    useSvgBackground = false,
    iconRadioButtonOptions
) => {
    return field.options
        .map(option => {
            const iconOptions = iconRadioButtonOptions?.[option.optionId];

            return createMarkup(
                option,
                field,
                texts,
                classes,
                errorMessageTargetClass,
                useSvgBackground,
                iconOptions?.alignment,
                iconOptions?.bottomIconSvg,
                iconOptions?.textBackgroundColorHex
            );
        })
        .join("");
};
