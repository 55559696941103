import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../close";

export const forgotPasswordMarkup = labels => {
    const {
        headerTitle,
        emailLabel,
        forgotPasswordText,
        sendLinkLabel,
        goBackLabel
    } = labels;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--login-forgot-password">
    <div class="animate-fade forgot-password-manchet text-center">${forgotPasswordText}</div>
        <div class="animate-translate input">
            <input autocomplete="off" required type="text" id="email" name="email" />
            <label for="email">${emailLabel}</label>
            <span class="error"></span>
        </div>
    </div>
    <div class="membership-footer membership-footer--login-forgot-password">
        <button class="animate-scale button button--primary send-link" type="submit">
            <span>${sendLinkLabel}</span>
        </button>
        <button type="button" class="animate-fade button-link button-link--secondary login">${goBackLabel}</button>
    </div>`);
};
