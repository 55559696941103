import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../close";

export const confirmationMarkup = labels => {
    const { headerTitle, resendEmailText, resendLabel } = labels;

    return parseHTML(`
    <div class="membership-header membership-header--signup-confirmation">
        ${closeMarkup}
        <h2 class="animate-translate membership-header__title">${headerTitle}</h2>
    </div>
    <div class="membership-footer membership-footer--signup-confirmation">
        <div class="membership-message animate-translate">${resendEmailText}<button type="button" class="animate-translate button-link button-link--secondary">${resendLabel}</button></div>
    </div>`);
};
