import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { hasClass } from "~/foundation/Dom/classList";

export const publicationMarkup = (dataObject, index) => {
    const isLoggedInMemberInternal = hasClass(
        document.body,
        "logged-in--internal"
    );

    return parseHTML(`
        <div class="publication__list publication__list--small">
            <div class="publication__list-inner-wrapper">
                <button
                data-pdf="${dataObject.downloadUrl}"
                data-href="${
                    window.location.pathname
                }?adobePDF=${index}" class="button--pdf restrict-content" ${
        dataObject.isInternal && !isLoggedInMemberInternal
            ? "data-restrict"
            : ""
    } aria-label="${dataObject.title}">
                    <picture>
                        <source media="(min-width: 768px)" data-srcset="${
                            dataObject.image?.smallImageUrl
                        }" />
                        <source data-srcset="${
                            dataObject.image?.mediumImageUrl
                        }" />
                        <img alt="${dataObject.title}" class="lazy" data-src="${
        dataObject.image?.mediumImageUrl
    }" />
                    </picture>
                </button>
            </div>
            <div class="publication__title">
                ${dataObject.title}
                ${
                    dataObject.isInternal
                        ? `
                        <div class="restrict-content__indication publication__restriction">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                                <path d="M6,7.09c-2,0-6,1.1-6,3.27V12H12V10.36C12,8.19,8,7.09,6,7.09Zm1.32-2a2.73,2.73,0,1,0-2.64,0,2.73,2.73,0,0,0,2.64,0Z"></path>
                            </svg>
                        </div>
                    `
                        : ""
                }
            </div>
            ${
                dataObject.publication !== ""
                    ? `<div class="publication__title">${dataObject.publication}</div>`
                    : ""
            }
            <div id="pdf-viewer__wrapper${index}" class="pdf-viewer__wrapper"></div>
        </div>
    `);
};
