export function loadAdobePDFScript() {
    const scriptEl = document.createElement("script");

    //scriptEl.src = "https://documentcloud.adobe.com/view-sdk/main.js";
    scriptEl.src = "https://documentservices.adobe.com/view-sdk/viewer.js";
    scriptEl.async = true;
    scriptEl.defer = true;

    document.getElementsByTagName("head")[0].appendChild(scriptEl);
}
