import { buildTextInput } from "~/feature/Volunteer/signup/inputs/buildTextInput";

const stepOneContentTemplate = ({ classes, model, sections }) => {
    return `
        <div class="${classes.rootClass}__event-info">
            <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
            <time
                data-async-module="local-datetime"
                data-date-format="event-date-time"
                data-utc-start="${model.eventModel.startDateUTC}"
                data-utc-end="${model.eventModel.endDateUTC}"
                data-date-fallback="${model.eventModel.dateString}"
                data-use-ksa="true"
            ></time>
        </div>
        <div class="${classes.rootClass}__event-intro">
            <h2>${model.texts.introTitle}</h2>
            ${model.texts.introText}
        </div>
        <div class="${classes.rootClass}__section">
            <h3>${sections.title}</h3>
            ${sections.fields
                .map(field => `${buildTextInput(field, classes)}`)
                .join("")}
        </div>
    `;
};

export default stepOneContentTemplate;
