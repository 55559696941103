import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const pressReleaseItemMarkup = data => {
    const { title, description, url, publishDate, largeImageUrl, imageAlt } =
        data;

    const imageMarkup = `<div class="press-release-list__image"><img src=${largeImageUrl} alt="${imageAlt}"/></div>`;

    return parseHTML(`
        <div class="press-release-list__item inview">
            <a href=${
                url ? url : ""
            } target="_blank" class="press-release-list__link">

            ${largeImageUrl ? imageMarkup : ""}
            <div class="press-release-list__content">
            ${
                publishDate
                    ? `<time
                        data-async-module="local-datetime"
                        data-utc-start="${publishDate}"
                        data-date-format="date"
                        class="local-datetime--not-initialized press-release-list__date">
                    </time>`
                    : ``
            }
            ${title ? `<h3>${title}</h3>` : ""}
            ${description ? `<p>${description}</p>` : ""}
            </div>
            </a>
        </div>
    `);
};
