import { Snackbar } from "~/foundation/Components/snackbar/snackbar";
import * as clipboard from "clipboard-polyfill/text";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { fetcher } from "~/foundation/Api";
import { ARTICLE_SHARE } from "~/foundation/Constants/api-end-points";

export class Share {
    constructor(domReference) {
        this.dom = { container: domReference };
        this.snackbarInstance = undefined;
        this.initialize();
        this.language = document.querySelector("html").getAttribute("lang");
    }

    copyLink = () => {
        clipboard.writeText(window.location.href);
        this.snackbarInstance = new Snackbar({
            element: this.dom.copyLinkButton,
            text: this.language === "ar" ? "تم نسخ الرابط" : "Link copied",
            autoClose: true,
            autoCloseDuration: 1000,
            onClose: () => {
                this.snackbarInstance = undefined;

                this.share();
            }
        });
    };

    shareFB = () => {
        const url = `https://facebook.com/sharer.php?display=popup&u=${window.location.href}`;

        const options = "toolbar=0,status=0,resizable=1,width=626,height=436";
        window.open(url, "sharer", options);

        this.share();
    };

    shareTwitter = () => {
        const url = `https://x.com/share?url=${window.location.href}`;

        const options = "toolbar=0,status=0,resizable=1,width=626,height=436";
        window.open(url, "sharer", options);

        this.share();
    };

    shareLinkedIn = () => {
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;

        const options = "toolbar=0,status=0,resizable=1,width=626,height=436";
        window.open(url, "sharer", options);

        this.share();
    };

    share = () => {
        const currentItemId = document.body.getAttribute(
            "data-current-item-id"
        );
        if (currentItemId) {
            fetcher(`${ARTICLE_SHARE}/${currentItemId}`, "POST");
        }
    };

    kill() {
        if (this.snackbarInstance) {
            this.snackbarInstance.close();
        }

        removeAllEvents(this.dom.copyLinkButton);
        removeAllEvents(this.dom.facebookShare);
        removeAllEvents(this.dom.twitterShare);
        removeAllEvents(this.dom.linkedinShare);
    }

    initialize() {
        this.dom.copyLinkButton =
            this.dom.container.querySelector(".copy-link");
        this.dom.facebookShare =
            this.dom.container.querySelector(".social--facebook");
        this.dom.twitterShare =
            this.dom.container.querySelector(".social--twitter");
        this.dom.linkedinShare =
            this.dom.container.querySelector(".social--linkedin");

        addEvent(this.dom.copyLinkButton, "click", this.copyLink);
        addEvent(this.dom.facebookShare, "click", this.shareFB);
        addEvent(this.dom.twitterShare, "click", this.shareTwitter);
        addEvent(this.dom.linkedinShare, "click", this.shareLinkedIn);
    }
}
