import { PhotoCompetition } from "./photo-competition";
import { PhotoCompetitionOpenPhotoModal } from "./photo-competition-open-photo-modal";

export class PhotoCompetitionLeaderboard extends PhotoCompetition {
    constructor(domReference) {
        super(domReference);
    }

    setDomElements(domReference) {
        this.dom.container = domReference;
        this.dom.galleryView = domReference.querySelector("[data-leaderboard]");
        this.dom.loader = domReference.querySelector("[data-leaderboard-loader]");
        this.dom.loadMoreButton = domReference.querySelector("[data-leaderboard-loadmore]");
        this.dom.uploadModal = document.querySelector("[data-module='photo-competition-open-upload-modal']");
        this.dom.openUploadModal = domReference.querySelector("[data-upload-button]");
        this.dom.orderBySelect = null;
        this.dom.orderByList = null;
        this.dom.orderByItems = null;
        this.dom.orderBySelectedValue = null;
        this.dom.linkList = domReference.querySelector("[data-gallery-linklist]");
    }

    async initialize() {
        this.highlighted = parseInt(this.dom.container.dataset.highlighted);
        await super.initialize();
        const daysLeftElement = this.dom.container.querySelector("[data-days-left]");

        if (daysLeftElement) {
            daysLeftElement.innerHTML = this.dictionary.daysLeft?.replace("{0}", this.competition.votingDaysLeft ?? this.competition.daysLeft);
        }
    }

    setSettings() {
        this.enableMasonry = false;
    }

    initializePhotoModal(element) {
        const listLikeElement = element.querySelector(".photo-leaderboard__total-likes");
        void new PhotoCompetitionOpenPhotoModal(element, this.dictionary, this.photoCompetitionService, this.likeablePhotos, listLikeElement, { onLike: this.updateLikes, onRemoveLike: this.updateLikes });
    }

    updateLikes(element, newLikesCount) {
        if (element && typeof newLikesCount === "number") {
            element.innerHTML = `${newLikesCount} ${newLikesCount === 1 ? this.dictionary.like : this.dictionary.likes}`;
        }
    }

    getTemplate(leaderboard, currentLength) {
        return leaderboard.map((galleryItem, index) => {
            const currentIndex = currentLength + index;

        //     return `
        //     <div class="photo-leaderboard__item ${(currentIndex + 1) <= this.highlighted ? "photo-leaderboard__item--highlighted" : ""}" data-photo-id="${galleryItem.id}" ${(galleryItem.liked ? `data-gallery-item-liked="true"` : "")} data-likes-count="${galleryItem.likesCount}">
        //         <div class="photo-leaderboard__item-content">
        //             <div class="photo-leaderboard__placement">
        //                 ${currentIndex + 1}
        //             </div>
        //             <img src="${galleryItem.srcSmall}" data-gallery-src-big="${galleryItem.srcBig}" />
        //             <div class="photo-leaderboard__meta">
        //                 <p data-gallery-name>${galleryItem.name}</p>
        //                 <p data-gallery-description>${galleryItem.description}</p>
        //             </div>
        //         </div>
        //         <div class="photo-leaderboard__total-likes">${galleryItem.likesCount} ${galleryItem.likesCount === 1 ? this.dictionary.like : this.dictionary.likes}</div>
        //     </div>
        // `

            return `
            <div class="photo-leaderboard__item ${(currentIndex + 1) <= this.highlighted ? "photo-leaderboard__item--highlighted" : ""}" data-photo-id="${galleryItem.id}" ${(galleryItem.liked ? `data-gallery-item-liked="true"` : "")} data-likes-count="${galleryItem.likesCount}">
                <div class="photo-leaderboard__item-content">
                    <div class="photo-leaderboard__placement">
                        ${currentIndex + 1}
                    </div>
                    <img src="${galleryItem.srcSmall}" data-gallery-src-big="${galleryItem.srcBig}" />
                    <div class="photo-leaderboard__meta">
                        <p data-gallery-name>${galleryItem.name}</p>
                        <p data-gallery-description>${galleryItem.description}</p>
                    </div>
                </div>
            </div>
        `
        }).join("");
    }
}
