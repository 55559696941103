import {
    appendElement,
    emptyElement
} from "~/foundation/Dom/elementManipulation";
import { hostOverviewMarkup } from "./markup";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { fetcher } from "~/foundation/Api";
import { hostMarkup } from "./host-markup";
import { Snackbar } from "~/foundation/Components/snackbar/snackbar";
import { isRtl } from "~/foundation/Helpers/isRtl";

export class HostOverviewScreen {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.container = config.container;
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;

        this.snackbar = undefined;

        this.getDependentHostApiUrl =
            config.apiEndPoints.getDependentHostApiUrl;
        this.deleteDependentHostRelationApiUrl =
            config.apiEndPoints.deleteDependentHostRelationApiUrl;

        this.callbacks = {
            onConfirmClick: config.onConfirmClick,
            onCancelClick: config.onCancelClick
        };

        this.host = {};

        this.initialize();
    }

    getHost = () => {
        return fetcher(this.getDependentHostApiUrl).then(result => {
            if (result.success) {
                this.host = result.data;
                const markup = hostMarkup(this.texts, result.data);
                appendElement(markup, this.dom.host);

                this.dom.disconnectLink =
                    this.dom.membershipContent.querySelectorAll(
                        ".button--disconnect"
                    );
                addEvent(this.dom.disconnectLink, "click", this.remove);
            }
        });
    };

    disconnectHost = () => {
        fetcher(this.deleteDependentHostRelationApiUrl, "POST", {
            DependentId: this.host.id
        }).then(result => {
            if (result.success) {
                this.callbacks.onConfirmClick();
            }
        });
    };

    kill = () => {
        removeAllEvents([this.dom.cancelButton]);
        this.callbacks.onCancelClick();
    };

    remove = element => {
        // Hide snackbar so we don't create multiple instances
        if (this.snackbar) {
            this.snackbar.close();
        }

        this.snackbar = new Snackbar({
            element: element.target,
            text: this.texts.confirmDeleteText,
            autoClose: false,
            confirm: true,
            cancelLabel: this.texts.noButtonLabel,
            confirmLabel: this.texts.yesButtonLabel,
            position: isRtl ? "right" : "left",
            onCancel: () => {
                this.snackbar.close();
                this.snackbar = undefined;
            },
            onConfirm: this.disconnectHost
        });
    };

    initialize() {
        this.getHost();
        const overviewHtml = hostOverviewMarkup(this.texts);

        emptyElement(this.dom.membershipContent);
        appendElement(overviewHtml, this.dom.membershipContent);

        this.dom.host =
            this.dom.membershipContent.querySelector(".dependent__host");

        this.dom.cancelButton =
            this.dom.container.querySelector(".button--cancel");
        addEvent(this.dom.cancelButton, "click", this.kill);
    }
}
