const stepFourTemplate = ({ classes, model, sections }) => {
    return `
        <div class="${classes.rootClass}__header">
            <h2 class="${classes.rootClass}__title">${sections.title}</h2>
            <p class="${classes.rootClass}__manchet">${sections.description}</p>
        </div>
        <div class="${classes.rootClass}__section">
            <div class="${classes.rootClass}__preview-offset">
                <div class="${classes.rootClass}__preview-container ${classes.rootClass}__conversion-node"></div>
            </div>
            <div class="${classes.rootClass}__preview-container"></div>
            <h3>${model.texts.shareButtonsText}</h3>
            <div class="${classes.rootClass}__share-buttons">
                <button class="button button--secondary button--secondary-blue greeting__download-image-button">${model.texts.downloadAsImageButtonLabel}</button>
            </div>
        </div>
    `;
};

export default stepFourTemplate;
