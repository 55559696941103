import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const nameMarkup = (labels, user) => {
    const {
        firstNameLabel,
        lastNameLabel,
        editNameLabel,
        cancelButtonLabel,
        saveButtonLabel
    } = labels;

    const { firstName, lastName, emailAddress } = user;

    return parseHTML(`
    <div>
        <div class="animate-translate name">
            <div class="animate-translate firstName">${firstName}</div>
            <div class="animate-translate lastName">${lastName}</div>
            <button type="button" class="animate-fade button-link button-link--secondary edit-name">${editNameLabel}</button>
        </div>
        <div class="animate-translate editable-name visuallyhidden">
            <div class="animate-translate input">
                <input required type="text" id="firstName" value="${firstName}" />
                <label for="firstName">${firstNameLabel}</label>
                <span class="error"></span>
            </div>
            <div class="animate-translate input">
                <input required type="text" id="lastName" value="${lastName}"/>
                <label for="lastName">${lastNameLabel}</label>
                <span class="error"></span>
            </div>
            <div class="animate-translate buttons">
                <button type="button" class="animate-fade button button--secondary button--secondary-white cancel-edit-name">${cancelButtonLabel}</button>
                <button type="button" class="animate-fade button button--secondary button--secondary-white save-name">${saveButtonLabel}</button>
            </div>
        </div>
        <div class="animate-translate">${emailAddress}</div>
    </div>`);
};
