import { buildRadioButtonInput } from "~/feature/Volunteer/signup/inputs/buildRadioButtonInput";

const stepThreeContentTemplate = ({ classes, sections }) => {
    const errorMessageStepThree = "step-three";

    return `
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 3</legend>
                <div class="${classes.rootClass}__radio-group ${
        classes.rootClass
    }__radio-group--button">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__stacking-list">
                        ${buildRadioButtonInput(
                            sections.fields[0],
                            errorMessageStepThree
                        )}
                    </ul>
                    <p class="${errorMessageStepThree}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepThreeContentTemplate;
