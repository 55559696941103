/*global OneTrust */
import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";

export class OnetrustToggle {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.trigger =
            this.dom.container.querySelector("button") || this.dom.container;

        onReady(() => this.initialize());
    }

    initialize() {
        addEvent(this.dom.trigger, "click", () => {
            if (typeof OneTrust !== "undefined") {
                OneTrust.ToggleInfoDisplay();
            }
        });
    }
}
