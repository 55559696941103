import { onReady } from "~/foundation/Events/onReady";
import { loadAdobePDFScript } from "~/feature/Article/pdf-viewer/loadAdobePDFScript";
import { getParameter } from "~/foundation/Helpers/queryString";
import { disableScrollLock, enableScrollLock } from "~/foundation/Dom/scrollLock";
import { addClass, hasClass, removeClass } from "~/foundation/Dom/classList";

const viewerConfig = {
    embedMode: "LIGHT_BOX",
    showDownloadPDF: false,
    showPrintPDF: false,
    defaultViewMode: hasClass(document.body, "is-app")
        ? "CONTINUOUS"
        : "FIT_PAGE"
};

export class PdfViewer {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.pdfButtons = this.dom.container.querySelectorAll(
            "button.button--pdf:not([data-restrict])"
        );
        this.adobeClientId = this.dom.container.dataset.adobeClientId;

        onReady(() => this.initialize());
    }

    //Not in use for now
    // positionPdfViewer() {
    //     const pageHeader = document.querySelector("header.header");
    //     const pdfViewers = document.querySelectorAll(".pdf-viewer__wrapper");
    //
    //     if (hasClass(document.body, "is-app")) {
    //     } else {
    //         if (pageHeader) {
    //             const headerHeight = Number(pageHeader.offsetHeight);
    //             const availableHeight = `${
    //                 window.innerHeight - headerHeight
    //             }px`;
    //             forEach(pdfViewers, pdfViewer => {
    //                 if (hasClass(pdfViewer, "pdf-viewer__wrapper--active")) {
    //                     // set the style in the dom
    //                     setStyles(pdfViewer, {
    //                         top: `${headerHeight}px`
    //                     });
    //                     //height is set with property to allow important
    //                     pdfViewer.style.setProperty(
    //                         "height",
    //                         availableHeight,
    //                         "important"
    //                     );
    //                 } else {
    //                     //height is set with property to allow important
    //                     pdfViewer.style.setProperty("height", 0, "important");
    //                 }
    //             });
    //         }
    //     }
    // }

    // eslint-disable-next-line no-unused-vars
    addCallBackEvents(adobeDCView, pdfModalWrapperId, scope) {
        adobeDCView.registerCallback(
            /* Type of call back */
            // eslint-disable-next-line no-undef
            AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            /* call back function */
            function (event) {
                if (event.type === "PDF_VIEWER_READY") {
                    addClass(
                        document.getElementById(pdfModalWrapperId),
                        "pdf-viewer__wrapper--active"
                    );
                    //scope.positionPdfViewer();
                }
                if (event.type === "PDF_VIEWER_CLOSE") {
                    disableScrollLock();
                    removeClass(
                        document.getElementById(pdfModalWrapperId),
                        "pdf-viewer__wrapper--active"
                    );
                    //scope.positionPdfViewer();
                }
            }
        );
    }

    bindEvents() {
        const scope = this;
        document.addEventListener("adobe_dc_view_sdk.ready", function () {
            const queryPDFNum = getParameter("adobePDF");

            if (queryPDFNum) {
                const pdfUrl =
                    scope.dom.pdfButtons[Number(queryPDFNum) - 1].dataset.pdf;
                const pdfFileName =
                    scope.dom.pdfButtons[Number(queryPDFNum) - 1].getAttribute(
                        "aria-label"
                    );
                const pdfModalWrapperId = `pdf-viewer__wrapper${queryPDFNum}`;
                enableScrollLock();
                // eslint-disable-next-line no-undef
                const adobeDCView = new AdobeDC.View({
                    clientId: scope.adobeClientId,
                    divId: pdfModalWrapperId
                });
                adobeDCView.previewFile(
                    {
                        content: {
                            location: {
                                url: pdfUrl
                            }
                        },
                        metaData: { fileName: pdfFileName }
                    },
                    viewerConfig
                );

                scope.addCallBackEvents(adobeDCView, pdfModalWrapperId, scope);
            }

            //Need to use delegated event since more editions can be loaded dynamically
            document.addEventListener("click", function (e) {
                if (e.target) {
                    const pdfButton = e.target.closest(".button--pdf");
                    if (pdfButton && !pdfButton.hasAttribute("data-restrict")) {
                        const pdfFileName =
                            pdfButton.getAttribute("aria-label");
                        const pdfModalWrapperId = pdfButton
                            .closest(".publication__list")
                            .querySelector(".pdf-viewer__wrapper")
                            .getAttribute("id");
                        // eslint-disable-next-line no-undef
                        const adobeDCView = new AdobeDC.View({
                            clientId: scope.adobeClientId,
                            divId: pdfModalWrapperId
                        });
                        adobeDCView.previewFile(
                            {
                                content: {
                                    location: {
                                        url: pdfButton.getAttribute("data-pdf")
                                    }
                                },
                                metaData: { fileName: pdfFileName }
                            },
                            viewerConfig
                        );

                        scope.addCallBackEvents(
                            adobeDCView,
                            pdfModalWrapperId,
                            scope
                        );
                    }
                }
            });
        });
    }

    initialize() {
        this.bindEvents();

        if (typeof AdobeDC === "undefined") {
            loadAdobePDFScript();
        }
    }
}
