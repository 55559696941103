import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const listItemMarkup = (labels, dependent) => {
    const { removeDependentButtonLabel } = labels;
    const { id, email } = dependent;

    return parseHTML(`
    <div class="dependent__list-item" data-id="${id}">
        ${email}
        <button type="button" class="button-link button-link--secondary button--icon button--delete">${removeDependentButtonLabel}</button>
    </div>
`);
};
