const API_VERSION = "/api/v1";

const MEMBERSHIP_USER = `${API_VERSION}/membership/user`;
const MEMBERSHIP_SIGNUP_START = `${API_VERSION}/membership/signup/start`;
const MEMBERSHIP_SELECTTOPICS_START = `${API_VERSION}/membership/topicscores/start`;
const MEMBERSHIP_PASSWORD_START = `${API_VERSION}/membership/password/start`;
const MEMBERSHIP_PROFILE_START = `${API_VERSION}/membership/profile/start`;
const MEMBERSHIP_DEPENDENT_START = `${API_VERSION}/membership/dependent/start`;
const MEMBERSHIP_LOGIN = `${API_VERSION}/membership/login/start`;
const MEMBERSHIP_LOGOUT = `${API_VERSION}/membership/logout`;
const COMMENTS_SAVE = `${API_VERSION}/article/comment/save`;
const COMMENTS_COUNT = `${API_VERSION}/article/comment/count`;
const CHECK_USER = `${API_VERSION}/membership/checkuser`;
const LIKE_SAVE = `${API_VERSION}/article/like/save`;
const LIKE_REMOVE = `${API_VERSION}/article/like/remove`;
const LIKE_COUNT = `${API_VERSION}/article/like/count`;
const ARTICLE_SHARE = `${API_VERSION}/article/share`;
const SEARCH = `${API_VERSION}/search/search`;
const SEARCH_CONFIGURATION = `${API_VERSION}/search/configuration`;
const POLL = `${API_VERSION}/poll/poll`;
const POLL_GLOBAL = `${API_VERSION}/poll/globalpoll`;
const POLL_ANSWER = `${API_VERSION}/poll/answer`;
const POLL_LIST = `${API_VERSION}/poll/list/latest`;
const POLL_QUIZ_LIST = `${API_VERSION}/poll/quiz/list`;

export {
    MEMBERSHIP_USER,
    MEMBERSHIP_SIGNUP_START,
    MEMBERSHIP_SELECTTOPICS_START,
    MEMBERSHIP_PASSWORD_START,
    MEMBERSHIP_LOGIN,
    MEMBERSHIP_LOGOUT,
    MEMBERSHIP_PROFILE_START,
    MEMBERSHIP_DEPENDENT_START,
    COMMENTS_SAVE,
    COMMENTS_COUNT,
    LIKE_SAVE,
    LIKE_REMOVE,
    LIKE_COUNT,
    ARTICLE_SHARE,
    SEARCH,
    SEARCH_CONFIGURATION,
    POLL,
    POLL_GLOBAL,
    POLL_ANSWER,
    POLL_LIST,
    POLL_QUIZ_LIST,
    CHECK_USER
};
