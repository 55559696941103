import { onReady } from "~/foundation/Events/onReady";
import {
    appendElement,
    deleteElement
} from "~/foundation/Dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { teaserMarkup } from "./markup/teaserMarkup";
import { publicationMarkup } from "./markup/publicationMarkup";
import { videoMarkup } from "./markup/videoMarkup";
import { fetcher } from "~/foundation/Api";
import { makeButtonLoad } from "~/foundation/Components/button/make-button-load";
import { forEach } from "~/foundation/Helpers/forEach";
import { setupInView } from "~/foundation/Dom/inView";
import LazyLoad from "vanilla-lazyload";
import { addClass, removeClass } from "~/foundation/Dom/classList";
import {
    breakpointIndex,
    currentWindowWidth
} from "~/foundation/Events/onWindowResize";
import { bootstrappedInstances, counts } from "~/foundation/Bootstrapper";
import { isRtl } from "~/foundation/Helpers/isRtl";
import objectFitImages from "object-fit-images";
import { volunteerCardMarkup } from "~/foundation/ListsLoadMore/markup/volunteerCardMarkup";
import { RestrictContent } from "../../feature/Membership/restrict-content/restrict-content";
import {travelDestinationMarkup} from "~/foundation/ListsLoadMore/markup/travelDestinationMarkup";

export class ListsLoadMore {
    /**
     * Internal placeholder for cached DOM-objects and settings objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};
    settings = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> lists-load-more
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.loadButton = this.dom.container.querySelector(
            "button[type=button]"
        );

        this.dom.appendList =
            this.dom.container.parentNode.querySelector("[data-append-list]");

        this.dom.noMoreResultsElement =
            this.dom.container.querySelector(".edition--hidden") || null;

        // Adding to the dom and settings object
        this.settings = {
            endpoint: this.dom.container.dataset.endpoint,
            startIndex:
                parseInt(this.dom.container.dataset.listStartindex) || 0,
            listLength: parseInt(this.dom.container.dataset.listLength) || 8,
            includeCategories:
                this.dom.container.dataset.includeCategories || false,
            excludeCategories:
                this.dom.container.dataset.excludeCategories || false,
            topicId: this.dom.container.dataset.topicid,
            publicationId: this.dom.container.dataset.publicationId,
            travelDestinationId: this.dom.container.dataset.travelDestinationId,
            showId: this.dom.container.dataset.showId,
            usePublicationMarkup:
                this.dom.container.dataset.publicationId || false,
            useVideoMarkup: this.dom.container.dataset.videoMarkup || false,
            useVolunteerMarkup:
                this.dom.container.dataset.volunteerMarkup || false,
            useTravelDestinationMarkup:
                this.dom.container.dataset.travelDestinationId || false,
            actualAppendListLength: parseInt(
                this.dom.appendList.querySelectorAll(".article-teaser").length
            )
        };

        // Special case with volunteer markup since we need translations for newly fetched items
        if (this.settings.useVolunteerMarkup) {
            this.translations = {
                pointsLabel: this.dom.container.dataset.pointsLabel,
                hoursLabel: this.dom.container.dataset.hoursLabel,
                memberStatusLabel: this.dom.container.dataset.memberStatusLabel,
                applyBeforeLabel:
                    this.dom.container.dataset.applyBeforeLabel || false
            };
        }

        // The startIndex value on the dataset is showing where the present data has its startIndex so we will increment this from the very beginning before fetching.
        this.settings.startIndex += this.settings.listLength;

        if (this.settings.startIndex < this.settings.actualAppendListLength) {
            this.settings.startIndex = this.settings.actualAppendListLength;
        }

        onReady(() => this.initialize());
    }

    getNewAppendContainer() {
        this.dom.appendList =
            this.dom.container.parentNode.querySelector("[data-append-list]");
    }

    updateTeaserList = () => {
        // Update the masonry list after getting new data
        // Check bootstrappedInstances for article-teaser-list's and updateMasonry for all of them
        if (
            bootstrappedInstances["article-teaser-list"] &&
            counts["article-teaser-list"]
        ) {
            for (
                let index = 0;
                index <= counts["article-teaser-list"];
                index++
            ) {
                if (index === 0) {
                    // Only update that teaser-list closest to load-more container
                    if (
                        bootstrappedInstances[`article-teaser-list`].dom
                            .container ===
                        this.dom.container.closest(
                            "[data-module=article-teaser-list]"
                        )
                    ) {
                        bootstrappedInstances[
                            `article-teaser-list`
                        ].updateMasonry();
                    }
                } else {
                    // Only update that teaser-list closest to load-more container
                    if (
                        bootstrappedInstances[`article-teaser-list-${index}`]
                            .dom.container ===
                        this.dom.container.closest(
                            "[data-module=article-teaser-list]"
                        )
                    ) {
                        const articleTeaserListClassInstance =
                            bootstrappedInstances[
                                `article-teaser-list-${index}`
                            ];

                        articleTeaserListClassInstance.updateMasonry();

                        if (isRtl) {
                            articleTeaserListClassInstance.updateRtlLayout();
                        }
                    }
                }
            }
        } else if (
            bootstrappedInstances["article-teaser-list"] &&
            !counts["article-teaser-list"]
        ) {
            bootstrappedInstances[`article-teaser-list`].updateMasonry();
        }
    };

    /**
     * Lets kill them events!
     */
    kill() {
        // Removing the load more event from the (hopefully) visibly hidden button
        removeAllEvents(this.dom.loadButton);
    }

    /**
     * Fetching the list data and handles all the magic happening inside the list.
     */
    fetchListData = () => {
        // Adding the loading state to the button
        const buttonLoader = new makeButtonLoad(
            this.dom.loadButton,
            isRtl ? "تحميل" : "Loading"
        );

        buttonLoader.load();

        const queryStringStart =
            this.settings.endpoint.indexOf("?") > -1 ? "&" : "?";

        // Fetch the data
        fetcher(
            `${
                this.settings.endpoint +
                queryStringStart +
                (this.settings.topicId
                    ? `topicId=${this.settings.topicId}&`
                    : "")
            }${
                this.settings.publicationId
                    ? `publicationId=${this.settings.publicationId}&`
                    : ""
            }${
                this.settings.travelDestinationId
                    ? `travelDestinationId=${this.settings.travelDestinationId}&`
                    : ""
            }startIndex=${this.settings.startIndex}&length=${
                this.settings.listLength
            }${
                this.settings.includeCategories
                    ? `&includeCategories=${this.settings.includeCategories}`
                    : ""
            }${
                this.settings.excludeCategories
                    ? `&excludeCategories=${this.settings.excludeCategories}`
                    : ""
            }${
                this.settings.useVideoMarkup
                    ? `&showId=${this.settings.showId}`
                    : ""
            }`
        ).then(({ data }) => {
            setTimeout(() => {
                buttonLoader.finish(true).then(() => {
                    deleteElement(this.dom.loadButton.querySelector("svg"));
                });
            }, 500);

            // Saving the total results for later use as well as parsing it as integer
            let totalResults = parseInt(data?.totalNumber);

            let loadedHtml = [];

            // We will load the correct markup based on the dataset set in the view. This needs to be done since it differs.
            forEach(data.results, dataObj => {
                let html;
                let adobePDFCount = data.results.indexOf(dataObj) + 1;

                if (this.settings.usePublicationMarkup) {
                    html = publicationMarkup(
                        dataObj,
                        this.settings.startIndex + adobePDFCount
                    );
                } else if (this.settings.useVideoMarkup) {
                    html = videoMarkup(dataObj);
                } else if (this.settings.useVolunteerMarkup) {
                    html = volunteerCardMarkup(
                        dataObj,
                        this.translations,
                        this.dom.container.dataset.pastEvents
                    );
                } else if (this.settings.useTravelDestinationMarkup) {
                    html = travelDestinationMarkup(
                        dataObj,
                        this.translations,
                        this.dom.container.dataset.pastEvents
                    );
                } else {
                    html = teaserMarkup(dataObj);
                }

                loadedHtml = [...loadedHtml, ...html];
            });

            appendElement(loadedHtml, this.dom.appendList);

            if (
                !this.settings.useVideoMarkup &&
                !this.settings.useVolunteerMarkup
            ) {
                const restrictContentElement = document.querySelector(
                    "[data-module='restrict-content']"
                );

                new RestrictContent(restrictContentElement);
            }

            // If we are not loading publications we want to initiate the article teaser class
            if (
                !this.settings.usePublicationMarkup &&
                currentWindowWidth >= breakpointIndex.sm
            ) {
                this.updateTeaserList();
            }

            // If we are using volunteer markup we will initiate the local datetime since we know it is included here
            if (this.settings.useVolunteerMarkup) {
                forEach(
                    this.dom.appendList.querySelectorAll(
                        '.local-datetime--not-initialized[data-async-module="local-datetime"]'
                    ),
                    localTimeItem => {
                        removeClass(
                            localTimeItem,
                            "local-datetime--not-initialized"
                        );
                    }
                );
            }

            // Initiating the lazyload and inview within our scope after we appended the data
            const inViewClass = ".inview";
            const inViewElements =
                this.dom.appendList.querySelector(inViewClass);

            if (inViewElements) {
                setupInView(inViewClass, "inview--active", "show", 0);
            }

            const lazyClass = ".lazy";
            const lazyloadConfig = {
                elements_selector: lazyClass,
                class_loading: "lz-loading",
                class_loaded: "lz-loaded"
            };

            // Check if there is any images to lazyLoad before init lazyload
            const lazyElements = this.dom.appendList.querySelector(lazyClass);
            if (lazyElements) {
                new LazyLoad(lazyloadConfig);
            }

            // Incrementing the stored startIndex with our listLength for every push. For obvious reasons.
            totalResults > this.settings.startIndex + this.settings.listLength
                ? (this.settings.startIndex += this.settings.listLength)
                : (this.settings.startIndex = totalResults);

            // Check if we have more results to add from the total. If not, hide the button and remove events.
            if (totalResults === this.settings.startIndex) {
                addClass(this.dom.loadButton, "button--hide");
                this.kill();

                if (
                    this.settings.usePublicationMarkup &&
                    this.dom.noMoreResultsElement
                ) {
                    removeClass(
                        this.dom.noMoreResultsElement,
                        "edition--hidden"
                    );
                }
            }

            const articleTeaserImages = document.querySelectorAll(
                ".article-teaser img"
            );

            objectFitImages(articleTeaserImages);
        });
    };

    initialize() {
        // Adding the actual click event for the button
        addEvent(this.dom.loadButton, "click", this.fetchListData);
    }
}
