import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";
import { addClass, removeClass } from "~/foundation/Dom/classList";
import { fetcher } from "~/foundation/Api";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
export class Feedback {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };
    settings = {};

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        onReady(() => this.initialize());
    }

    handleFormSubmit = event => {
        event.preventDefault();

        // disable button when fetching
        this.dom.feedbackFormSubmit.disabled = true;

        // Get form fields
        const formData = new FormData(event.currentTarget);
        const plainFormData = Object.fromEntries(formData.entries());

        fetcher(this.settings.endpoint, "POST", {
            PageUrl: plainFormData.PageUrl,
            Body: plainFormData.Body
        }).then(result => {
            if (result) {
                const { success } = result;
                if (success) {
                    removeClass(this.dom.feedbackPoster, "feedback--active");
                    addClass(this.dom.feedbackSuccess, "feedback--active");
                } else {
                    removeClass(this.dom.feedbackPoster, "feedback--active");
                    removeClass(
                        this.dom.feedbackContent,
                        "feedback__content--active"
                    );

                    addClass(this.dom.feedbackError, "feedback--active");

                    // enable button again
                    this.dom.feedbackFormSubmit.disabled = false;
                }
            } else {
                alert("ERROR");
            }
        });
    };

    showFeedbackForm = () => {
        removeClass(this.dom.feedbackStart, "feedback--active");
        addClass(this.dom.feedbackPoster, "feedback--active");

        // Animating the feedback content
        // Create a timeline with default parameters
        const tl = anime.timeline({
            easing: STANDARDCUBICBEZIER
        });

        tl.add({
            targets: this.dom.feedbackPoster,
            opacity: 1,
            duration: 200
        });

        tl.add(
            {
                targets: this.dom.feedbackFormContent,
                opacity: [0, 1],
                translateY: [50, 0]
            },
            220
        );

        tl.add(
            {
                targets: this.dom.feedbackFormSubmit,
                translateY: [50, 0]
            },
            240
        );
    };

    initialize() {
        // Start screen
        this.dom.feedbackStart =
            this.dom.container.querySelector(".feedback--start");
        this.dom.feedbackStartButton =
            this.dom.feedbackStart?.querySelector("button");

        // Post screen
        this.dom.feedbackPoster =
            this.dom.container.querySelector(".feedback--form");

        this.dom.feedbackFormContent = this.dom.container.querySelector(
            ".feedback--form .feedback__content"
        );

        this.dom.feedbackForm = this.dom.feedbackPoster?.querySelector("form");
        this.dom.feedbackFormSubmit =
            this.dom.feedbackForm?.querySelector("button");

        this.settings.endpoint = this.dom.feedbackForm?.getAttribute("action");

        // Success screen
        this.dom.feedbackSuccess =
            this.dom.container.querySelector(".feedback--success");

        // Error screen
        this.dom.feedbackError =
            this.dom.container.querySelector(".feedback--error");
        this.dom.feedbackErrorSubmit =
            this.dom.feedbackError?.querySelector("button");

        if (this.dom.feedbackStartButton) {
            // Go from start screen to post a message
            addEvent(
                this.dom.feedbackStartButton,
                "click",
                this.showFeedbackForm
            );
        }

        if (this.dom.feedbackForm) {
            // Post the message
            addEvent(this.dom.feedbackForm, "submit", this.handleFormSubmit);
        }

        if (this.dom.feedbackErrorSubmit) {
            // Try again if something went wrong
            addEvent(this.dom.feedbackErrorSubmit, "click", () => {
                removeClass(this.dom.feedbackError, "feedback--active");
                addClass(this.dom.feedbackStart, "feedback--active");
            });
        }
    }
}
