/* eslint-disable no-unused-expressions */
import { onReady } from "~/foundation/Events/onReady";
import { breakpointMin } from "~/foundation/Events/onWindowResize";
import { setStyles } from "~/foundation/Dom/setStyles";
import Flickity from "flickity";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
import { SLOW, MEDIUM, VERYSLOW, FAST } from "~/foundation/Constants/durations";
import LazyLoad from "vanilla-lazyload";
import { forEach } from "~/foundation/Helpers/forEach";
import { isIE11 } from "~/foundation/Helpers/isIE11";

export class TopStorySlider {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.currentAnimationSlide = 0;
        this.currentOffsideSlideIndex = 0;
        this.previousSlide = 0;
        this.lastOffsideIndex = 0;
        this.flktyXisNegative = false;

        const docStyle = document.documentElement.style;
        this.transformProp =
            typeof docStyle.transform == "string"
                ? "transform"
                : "WebkitTransform";
        this.carouselItemsOnScreen = 1;
        this.lazyLoadInstance = undefined;
        this.lazyCount = 0;
        this.isPresenting = true;
        this.sneakPeakAnimation = true;

        onReady(() => this.initialize());
    }

    // Remove all flickity listeners and destroy it
    kill() {
        this.flkty.off("scroll");
        this.flkty.off("settle");
        this.flkty.off("dragStart");
        this.flkty.off("dragEnd");
        this.flkty.destroy();
    }

    // Parallax current item (item on far left)
    animateSlide() {
        const flktyX = this.flkty.x;
        const currentSlide = this.flkty.slides[this.currentAnimationSlide];

        if (currentSlide) {
            const img = this.dom.slideImages[this.currentAnimationSlide];
            const topics = this.dom.slideTopics[this.currentAnimationSlide];
            const teaserText =
                this.dom.slideTeaserTexts[this.currentAnimationSlide];

            const slideTarget = currentSlide.target;

            let slideTranslate = slideTarget + flktyX;

            if (this.flktyXisNegative) {
                const multiplyWhenNegative =
                    this.dom.allSlides.length - this.currentAnimationSlide;
                slideTranslate =
                    flktyX - currentSlide.outerWidth * multiplyWhenNegative;
            }

            const imgX = Math.round((slideTranslate * -1) / 1.8);
            const topicsX = Math.round((slideTranslate * -1.5) / 2);
            const teaserTextX = Math.round((slideTranslate * -1) / 3.5);

            img.style[this.transformProp] = `translateX(${
                imgX < 0 ? 0 : imgX
            }px)`;
            topics.style[this.transformProp] = `translateX(${
                topicsX < 0 ? 0 : topicsX
            }px)`;
            teaserText.style[this.transformProp] = `translateX(${
                teaserTextX < 0 ? 0 : teaserTextX
            }px)`;
        }

        this.lastOffsideIndex = this.currentOffsideSlideIndex;

        if (this.currentAnimationSlide === this.flkty.slides.length - 1) {
            this.currentOffsideSlideIndex = 0;
        } else {
            this.currentOffsideSlideIndex = this.currentAnimationSlide + 1;
        }

        if (this.carouselItemsOnScreen === 1) {
            const offsideSlide =
                this.flkty.slides[this.currentOffsideSlideIndex];

            if (offsideSlide) {
                const slideTarget = offsideSlide.target;

                let slideTranslate = slideTarget + flktyX;

                if (this.flktyXisNegative) {
                    const multiplyWhenNegative =
                        this.dom.allSlides.length - this.currentAnimationSlide;
                    slideTranslate =
                        flktyX -
                        currentSlide.outerWidth * multiplyWhenNegative +
                        currentSlide.outerWidth;
                }

                const topicsX = Math.round((slideTranslate * 1) / 2);
                const teaserTextX = Math.round((slideTranslate * 1) / 3);
                const offsideTopics =
                    this.dom.slideTopics[this.currentOffsideSlideIndex];

                const offsideteaserText =
                    this.dom.slideTeaserTexts[this.currentOffsideSlideIndex];

                offsideTopics.style[
                    this.transformProp
                ] = `translateX(${topicsX}px)`;

                offsideteaserText.style[
                    this.transformProp
                ] = `translateX(${teaserTextX}px)`;
            }

            if (this.lastOffsideIndex !== this.currentOffsideSlideIndex) {
                this.dom.slideTopics[this.lastOffsideIndex].style[
                    this.transformProp
                ] = `translateX(0px)`;

                this.dom.slideTeaserTexts[this.lastOffsideIndex].style[
                    this.transformProp
                ] = `translateX(0px)`;
            }
        }

        if (this.previousSlide !== this.currentAnimationSlide) {
            if (this.dom.slideImages[this.previousSlide]) {
                this.dom.slideImages[this.previousSlide].style[
                    this.transformProp
                ] = "translateX(0px)";
            }

            if (this.dom.slideTeaserTexts[this.previousSlide]) {
                this.dom.slideTeaserTexts[this.previousSlide].style[
                    this.transformProp
                ] = "translateX(0px)";
            }

            if (this.dom.slideTopics[this.previousSlide]) {
                this.dom.slideTopics[this.previousSlide].style[
                    this.transformProp
                ] = "translateX(0px)";
            }
        }
    }

    visibleImagesLoaded = () => {
        // Presentation animation
        const tl = anime.timeline({
            easing: STANDARDCUBICBEZIER,
            duration: SLOW
        });

        this.dom.carousel.classList.add("carousel--loaded");

        if (this.isPresenting) {
            tl.add(
                {
                    targets: Array.from(this.dom.allSlides).slice(
                        0,
                        this.carouselItemsOnScreen
                    ),
                    scale: [0.98, 1],
                    opacity: [0, 1],
                    delay: anime.stagger(FAST)
                },
                0
            );
        }
        //apply sneakpeak animation on the slide just outside of viewport on larger screens
        if (this.sneakPeakAnimation) {
            if (breakpointMin("sm")) {
                const el = this.dom.allSlides[this.carouselItemsOnScreen];
                const elStyleTransform = window.getComputedStyle(el).transform;
                // eslint-disable-next-line no-undef
                const matrix = new WebKitCSSMatrix(elStyleTransform);

                tl.add({
                    targets: this.dom.allSlides[this.carouselItemsOnScreen],
                    opacity: [0, 1],
                    translateX: [
                        { value: matrix.m41 - 50, duration: VERYSLOW },
                        { value: matrix.m41, duration: VERYSLOW }
                    ],
                    begin: () => {
                        setStyles(
                            this.dom.allSlides[this.carouselItemsOnScreen],
                            {
                                zIndex: 5
                            }
                        );
                    },
                    complete: () => {
                        setStyles(
                            this.dom.allSlides[this.carouselItemsOnScreen],
                            {
                                zIndex: 1
                            }
                        );
                    }
                });
            }
        }
        // Only show pagination if slideable
        if (this.carouselItemsOnScreen !== this.carouselSlidesCount) {
            if (this.isPresenting) {
                tl.add(
                    {
                        targets: this.dom.paginationDots,
                        translateY: [20, 0],
                        opacity: [0, 1],
                        delay: anime.stagger(MEDIUM / 2)
                    },
                    700
                );
            }
        } else {
            this.pagination.style.display = "none";
        }
    };

    // Look for images to load
    loadImages = () => {
        const loadedImages = this.dom.carousel.querySelectorAll(
            ".lazy-slide:not(.loaded)"
        );

        if (loadedImages.length) {
            this.lazyLoadInstance.loadAll();
        }
    };

    activateFlickity = () => {
        //Don't show carousel if not enough slides to do the sliding

        if (this.carouselSlidesCount > this.carouselItemsOnScreen) {
            this.flkty.isActive = true;
            this.flkty.draggable = true;
            this.flkty.isDraggable = true;
        }
    };

    initialize() {
        this.dom.carousel = this.dom.container.querySelector(".carousel");
        this.dom.slideImages = this.dom.carousel.querySelectorAll(
            ".top-story-slide .top-story-slide__media"
        );
        this.dom.slideTeaserTexts = this.dom.carousel.querySelectorAll(
            ".top-story-slide .teaser-text"
        );
        this.dom.slideTopics = this.dom.carousel.querySelectorAll(
            ".top-story-slide .top-story-slide__category"
        );
        this.dom.allSlides =
            this.dom.carousel.querySelectorAll(".top-story-slide");
        this.dom.slideContents = this.dom.carousel.querySelectorAll(
            ".top-story-slide .top-story-slide__content"
        );
        this.dom.volunteerVisuals = this.dom.carousel.querySelectorAll(
            ".volunteer-card__visual > svg"
        );

        this.dom.pageHeader = document.querySelector("header.header");
        let headerHeight = 0;

        if (this.dom.pageHeader) {
            const headerComputedHeight = window.getComputedStyle(
                this.dom.pageHeader
            );
            const headerPaddingBottom = parseFloat(
                headerComputedHeight.paddingBottom
            );
            headerHeight =
                this.dom.pageHeader.clientHeight - headerPaddingBottom;
        }

        const carouselHeight = this.dom.carousel.clientHeight;
        this.carouselSlidesCount = this.dom.allSlides.length;

        if (breakpointMin("xl") && this.carouselSlidesCount > 3) {
            this.carouselItemsOnScreen = 4;
        } else if (breakpointMin("md") && this.carouselSlidesCount > 2) {
            this.carouselItemsOnScreen = 3;
        } else if (breakpointMin("sm")) {
            this.carouselItemsOnScreen = 2;
        }

        // css calc leaves gaps between slides so this helps
        const carouselWidth = this.dom.carousel.clientWidth;
        let contentHeight = 0;
        forEach(this.dom.allSlides, slide => {
            if (this.carouselItemsOnScreen === 1) {
                slide.style.width = `${carouselWidth}px`;
            } else if (
                this.carouselSlidesCount === this.carouselItemsOnScreen
            ) {
                slide.style.width = `${
                    carouselWidth / this.carouselItemsOnScreen
                }px`;
            } else if (this.carouselSlidesCount < this.carouselItemsOnScreen) {
                slide.style.width = `${
                    carouselWidth / this.carouselSlidesCount
                }px`;
            } else {
                slide.style.width = `${
                    carouselWidth / this.carouselItemsOnScreen - 10
                }px`;
            }

            if (
                slide.querySelector(".top-story-slide__content").clientHeight >
                contentHeight
            ) {
                contentHeight = slide.querySelector(
                    ".top-story-slide__content"
                ).clientHeight;
            }
        });

        // To align the content elements regardless of text amount
        forEach(this.dom.slideContents, slideContent => {
            slideContent.style.height = `${contentHeight}px`;
        });

        forEach(this.dom.volunteerVisuals, volunteerVisual => {
            volunteerVisual.style.top = headerHeight;
            volunteerVisual.style.height =
                carouselHeight - contentHeight - headerHeight;
        });

        // Don't show slide peek if not enough slides
        if (this.carouselSlidesCount === this.carouselItemsOnScreen) {
            this.sneakPeakAnimation = false;
        }

        const flktyConfig = {
            contain: true,
            cellAlign: "left",
            wrapAround: true,
            prevNextButtons: false,
            lazyLoad: this.carouselItemsOnScreen,
            pageDots: true,
            percentPosition: false,
            on: {
                ready: () => {
                    this.pagination = this.dom.carousel.querySelector(
                        ".flickity-page-dots"
                    );
                    this.dom.paginationDots =
                        this.dom.carousel.querySelectorAll(
                            ".flickity-page-dots .dot"
                        );

                    const scope = this;
                    this.lazyLoadInstance = new LazyLoad({
                        elements_selector: ".lazy-slide",
                        callback_loaded: function (el) {
                            if (isIE11) {
                                scope.dom.carousel.classList.add(
                                    "carousel--loaded"
                                );
                            } else {
                                //note: visibleImagesLoaded is controlling the visiblity toggle of carousel by adding class carousel--loaded
                                //we want to toggle this when all visible images on screen are loaded
                                //trigger index must be increased with length of volunteer svg's since these does not trigger loading callback event.
                                let triggerIndex =
                                    Array.from(scope.dom.allSlides).indexOf(
                                        el.closest(".top-story-slide")
                                    ) +
                                    1 +
                                    scope.dom.volunteerVisuals.length;
                                if (
                                    triggerIndex >= scope.carouselItemsOnScreen
                                ) {
                                    scope.visibleImagesLoaded();
                                }
                            }
                        },
                        callback_error: this.visibleImagesLoaded
                    });
                },
                dragStart: () => {
                    const loadedImages = this.dom.carousel.querySelectorAll(
                        ".lazy-slide:not(.loaded)"
                    );
                    if (loadedImages.length) {
                        // Turn off presentation animations
                        this.isPresenting = false;
                        this.lazyLoadInstance.loadAll();
                    }

                    // Every slide is a link so this makes it not go to that link when using the carousel
                    this.dom.container.classList.add("translating");
                },
                dragEnd: () => {
                    this.dom.container.classList.remove("translating");
                },
                settle: () => {
                    this.dom.container.classList.remove("translating");
                }
            }
        };

        if (breakpointMin("lg")) {
            flktyConfig.freeScroll = true;
        }

        this.flkty = new Flickity(this.dom.carousel, flktyConfig);

        // On init disable flickity until images has loaded
        this.flkty.isActive = false;
        this.flkty.draggable = false;
        this.flkty.isDraggable = false;

        if (this.carouselItemsOnScreen !== this.carouselSlidesCount) {
            // Checking if we have enough slides to actually create the slider
            this.activateFlickity();

            //Load rest of product images when user click on slider.
            this.flkty.once("staticClick", this.loadImages);

            this.flkty.on("scroll", (event, progress) => {
                this.previousSlide = this.currentAnimationSlide;

                const carouselWidth =
                    this.carouselItemsOnScreen === 1
                        ? this.flkty.size.width
                        : this.flkty.size.width -
                          this.carouselItemsOnScreen * 10; // Width minus the peek and the gradient
                const slideProgress = Math.round(
                    (progress / (carouselWidth / this.carouselItemsOnScreen)) *
                        100
                );
                const currentSlide = Math.floor(slideProgress / 100);

                // Update currentAnimation slide, reverse it if the carousel is translating backwards.
                if (Math.sign(slideProgress) === -1) {
                    this.flktyXisNegative = true;
                    this.currentAnimationSlide =
                        this.carouselSlidesCount - Math.abs(currentSlide);
                } else {
                    this.flktyXisNegative = false;
                    this.currentAnimationSlide =
                        currentSlide > this.carouselSlidesCount
                            ? this.carouselSlidesCount
                            : currentSlide;
                }

                this.animateSlide();
            });
        }
    }
}
