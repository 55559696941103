/**
 * Find out whether or not email is a valid email
 *
 * @param {*} string - The email to check
 * @returns {boolean} `true` if the given string is an email
 */

export function validateEmail(email) {
    const re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
