import { parseHTML } from "~/foundation/Dom/elementManipulation";
import checkmark from "~/foundation/Icons/checkmark";
import { closeMarkup } from "../../../close";

export const selectTopicsMarkup = labels => {
    const {
        headerTitle,
        continueLabel,
        confirmationText,
        confirmationResponseText,
        skipLabel,
        topics
    } = labels;

    let topicsString = "";

    for (let index = 0; index < topics.length; index++) {
        const item = topics[index];
        topicsString =
            topicsString +
            `<button id="${item.id}" class="button button--secondary button--secondary-white" type="button">
        <span>${item.title}</span>
        <span class="icon">
            ${checkmark}
        </span>
        </button>`;
    }

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title">${headerTitle}</h2>
    </div>
    <div class="membership-middle membership-middle--signup-topics">
        <div class="signup-topics">
        ${topicsString}
        </div>
    </div>
    <span class="select-topics-confirmation-text">${confirmationText}</span>
    <div class="membership-footer membership-footer--signup-topics">
        <button class="animate-scale button button--primary" type="submit">
            <span>${continueLabel}</span>
        </button>
        <button type="button" class="animate-fade button-link button-link--secondary skip">${skipLabel}</button>
    </div>
    <svg class="belly" focusable="false" width="100%" height="100vh" preserveAspectRatio="none" style="transform-origin: 50% 0%;" viewBox="0 0 375 812">
        <defs>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-1">
                <stop stop-color="#00A3E0" offset="0.0682910839%"></stop>
                <stop stop-color="#00A3E0" offset="10.0604165%"></stop>
                <stop stop-color="#84BD00" offset="90.4490401%"></stop>
                <stop stop-color="#84BD00" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M0,0 L15,0 C15,180.533529 15,361.067057 15,541.600586 C15,610.50293 15,623.562012 15,679.405273 C15,735.248535 15,735.248535 15,791.091797 C15,798.061198 15,805.030599 15,812 L0,812 L0,0 Z" id="Rectangle-Copy" fill="url(#linearGradient-1)"></path>
        </g>
    </svg>
    <button type="submit" class="select-topics-continue">
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" width="29" height="18" viewBox="0 0 29 18">
            <path fill="#FFF" fill-rule="evenodd" d="M15.415 0l-1.369 1.318 7.013 6.75H5v1.864l16.059-.001-7.013 6.751L15.416 18l7.98-7.683.235.227.637-.613.096.001.001-.095.635-.61L24.765 9 25 8.774l-.635-.612-.001-.094h-.096l-.637-.612-.235.226L15.416 0z"/>
        </svg>
        <span>${continueLabel}</span>
    </button>
    <span class="select-topics-confirmationResponse-text">${confirmationResponseText}</span>
    `);
};
