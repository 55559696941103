export const buildTextInput = (field, classes) => {
    const errorMessageTargetClass = `${field.key}`;

    return `
        <div class="${classes.rootClass}__text-group animate-translate input">
            <input type="text"
                autocomplete="off"
                name="${field.key}"
                value="${field.value || ""}"
                data-error-message="${field.defaultErrorMessage}"
                data-error-container="${errorMessageTargetClass}"
                ${field.readOnly ? ` readonly` : ``}
                ${field.isRequired ? ` required` : ``}
                id="${field.key}"
            />
            <label for="${field.key}">${field.label}</label>
        </div>
        <div class="${errorMessageTargetClass}"></div>
    `;
};
