import { isIE11 } from "~/foundation/Helpers/isIE11";

export const teaserImageMarkup = data => {
    const {
        teaserSize,
        image,
        listTeaserTitle,
        title,
        hasVideo,
        thumbnailUrl
    } = data;
    const altText = listTeaserTitle ? listTeaserTitle : title;
    const imageMarkup = `<img alt="${altText}" ${
        isIE11
            ? `src="${image?.largeImageUrl}"`
            : `class="lazy" data-src="${getImageUrl(
                  teaserSize,
                  hasVideo,
                  altText,
                  image
              )}`
    }" />`;
    const thumbnailMarkup = `<img alt="${altText}" ${
        isIE11
            ? `src="${thumbnailUrl}"`
            : `class="lazy" data-src="${thumbnailUrl}`
    }" />`;
    const noscriptMarkup = `<img alt="${altText}" src="${thumbnailUrl}" />`;

    const videoImageMarkup = `
                ${image.hasValue ? imageMarkup : thumbnailMarkup}
                <noscript>
                    ${noscriptMarkup}
                </noscript>
                <span class="play">
                    <svg focusable="false" width="28px" height="20px" viewBox="0 0 28 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <polygon points="0 0 35.483871 0 35.483871 24.9999798 0 24.9999798"></polygon>
                        </defs>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-859.000000, -3932.000000)">
                                <g transform="translate(855.000000, 3930.000000)">
                                    <mask fill="white">
                                        <use xlink:href="#path-1"></use>
                                    </mask>
                                    <path d="M15.1363636,16.2208065 L15.1363636,7.77919355 L22.4545455,12.0001613 L15.1363636,16.2208065 Z M31.4148636,5.12306452 C31.0928636,3.89370968 30.1440455,2.92564516 28.9394091,2.59709677 C26.7558864,2 18,2 18,2 C18,2 9.24411364,2 7.06059091,2.59709677 C5.85595455,2.92564516 4.90713636,3.89370968 4.58513636,5.12306452 C4,7.35112903 4,12 4,12 C4,12 4,16.6487097 4.58513636,18.8769355 C4.90713636,20.1062903 5.85595455,21.0743548 7.06059091,21.4030645 C9.24411364,22 18,22 18,22 C18,22 26.7558864,22 28.9394091,21.4030645 C30.1440455,21.0743548 31.0928636,20.1062903 31.4148636,18.8769355 C32,16.6487097 32,12 32,12 C32,12 32,7.35112903 31.4148636,5.12306452 L31.4148636,5.12306452 Z" id="Fill-1" fill="#FFFFFF" mask="url(#mask-2)"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
        `;
    return hasVideo ? videoImageMarkup : imageMarkup;
};

const getImageUrl = (teaserSize, hasVideo, altText, image) => {
    switch (teaserSize) {
        case "small":
            return image?.smallImageUrl;
        case "medium":
            return image?.mediumImageUrl;
        case "large":
        default:
            return image?.largeImageUrl;
    }
};
