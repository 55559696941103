import { parseHTML } from "~/foundation/Dom/elementManipulation";
import viewPasswordSvg from "~/foundation/Icons/view-password";
import { closeMarkup } from "../../../close";

export const passwordMarkup = labels => {
    const {
        headerTitle,
        passwordLabel,
        passwordHelpText,
        termsText,
        subscribeText,
        continueLabel,
        termsAndConditionUrl
    } = labels;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--signup-password">
        <div class="animate-translate input">
            <input autocomplete="off" required type="password" id="password" class="password" />
            <label for="password">${passwordLabel}</label>
            <span class="error"></span>
            <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
        </div>
        <div class="animate-translate password-guide">
            ${passwordHelpText}
        </div>
        <div class="checkbox-container">
            <div class="animate-translate checkbox">
                <input type="checkbox" id="terms" name="terms" />
                <label for="terms">
                    <span class="checkbox__labelText">
                        <a href="${termsAndConditionUrl}" target="_blank">${termsText}</a>
                    </span>
                    <div class="checkbox__labelBox">
                        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                            <path fill="none" fill-rule="evenodd" stroke="#00AAE2" stroke-width="2" d="M138 23L128.084 35 124 29.863" transform="translate(-123 -22)"/>
                        </svg>
                    </div>
                </label>
                <span class="error"></span>
            </div>
            <div class="animate-translate checkbox">
                <input type="checkbox" id="subscribe" name="subscribe" />
                <label for="subscribe">
                    <span class="checkbox__labelText">
                        ${subscribeText}
                    </span>
                    <div class="checkbox__labelBox">
                        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                            <path fill="none" fill-rule="evenodd" stroke="#00AAE2" stroke-width="2" d="M138 23L128.084 35 124 29.863" transform="translate(-123 -22)"/>
                        </svg>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="membership-footer membership-footer--signup-password">
        <button disabled type="submit" class="animate-scale button button--primary">
            <span>${continueLabel}</span>
        </button>
        <div class="signup-pagination">
            <button id="1" class="signup-pagination__item"></button>
            <button id="2" class="signup-pagination__item"></button>
            <button id="3" class="signup-pagination__item signup-pagination__item--current"></button>
        </div>
        <div class="captcha"></div>
    </div>`);
};
