const stepEightContentTemplate = ({ classes, sections }) => {
    return `
        <div class="${classes.rootClass}__section">
            <div class="${classes.rootClass}__event-intro">
                <h2>${sections.confirmationTitle}</h2>
                <p>${sections.confirmationText}</p>
            </div>
        </div>
    `;
};

export default stepEightContentTemplate;
