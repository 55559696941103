import {
    appendElement,
    emptyElement
} from "~/foundation/Dom/elementManipulation";
import {
    addEventOnce,
    addEvent,
    removeAllEvents
} from "~/foundation/Events/events";
import { changeDirection } from "~/foundation/Helpers/changeDirection";
import { selectLanguageMarkup } from "./markup";

export class SelectLanguageScreen {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.languages = config.languages;

        this.callbacks = {
            onChangeLanguage: config.onChangeLanguage,
            onSelectLanguage: config.onSelectLanguage,
            onLoginButtonClick: config.onLoginButtonClick,
            onInit: config.onInit
        };

        this.initialize();
    }

    selectLanguageAndContinue = () => {
        removeAllEvents(this.dom.languageButtons);
        this.callbacks.onSelectLanguage(this.selectedLanguageCode);
    };

    changeLanguage = e => {
        const button = e.target;
        this.dom.selectedLanguageButton.classList.remove("button--selected");
        button.classList.add("button--selected");
        this.dom.selectedLanguageButton = button;
        this.selectedLanguageCode = button.id;
        const htmlElement = document.getElementsByTagName("html")[0];
        const language = htmlElement.getAttribute("lang");

        if (language !== this.selectedLanguageCode) {
            emptyElement(this.dom.membershipContent);
            changeDirection();
            window.history.replaceState("", "", this.selectedLanguageCode);
            this.callbacks.onChangeLanguage(this.selectedLanguageCode);
        }
    };

    initialize() {
        const selectLanguageHtml = selectLanguageMarkup({
            headerTitle: this.texts.introText,
            signupTitle: `<strong>${this.texts.languageLabel}</strong><br/>${this.texts.languageHelpText}`,
            languages: this.languages,
            continueLabel: this.texts.startButtonLabel,
            logInLabel: this.texts.alreadyHaveAccountLinkText
        });

        appendElement(selectLanguageHtml, this.dom.membershipContent);

        this.dom.membershipContent.className =
            "membership__content membership__content--signup-select-language";
        this.dom.continueButton = this.dom.membershipContent.querySelector(
            ".membership-footer .button"
        );
        this.dom.selectedLanguageButton =
            this.dom.membershipContent.querySelector(
                ".select-language .button--selected"
            );
        this.selectedLanguageCode = this.dom.selectedLanguageButton.id;
        this.dom.languageButtons = this.dom.membershipContent.querySelectorAll(
            ".select-language .button"
        );
        const loginButton =
            this.dom.membershipContent.querySelector("button.login");

        addEvent(this.dom.languageButtons, "click", this.changeLanguage);
        this.callbacks.onInit(this.selectedLanguageCode);
        addEventOnce(loginButton, "click", this.callbacks.onLoginButtonClick);
        addEventOnce(
            this.dom.continueButton,
            "click",
            this.selectLanguageAndContinue
        );
    }
}
