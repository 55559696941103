import {
    appendElement,
    emptyElement
} from "~/foundation/Dom/elementManipulation";
import { confirmAddMarkup } from "./markup";
import { showError } from "~/foundation/Form/input/input-errors";
import { fetcher } from "~/foundation/Api";
import { makeButtonLoad } from "~/foundation/Components/button/make-button-load";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";

export class ConfirmAddScreen {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.container = config.container;
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.data = config.data;

        this.callbacks = {
            onConfirmSuccess: config.onConfirmSuccess,
            onCancelClick: config.onCancelClick
        };

        this.addDependentApiUrl = config.apiEndPoints.addDependentApiUrl;

        this.initialize();
    }

    addDependent = () => {
        const buttonLoader = new makeButtonLoad(
            this.dom.confirmButton,
            this.texts.loadingText
        );
        buttonLoader.load();

        fetcher(this.addDependentApiUrl, "POST", {
            email: this.data.email
        }).then(result => {
            buttonLoader.finish(true).then(() => {
                if (result.success) {
                    this.callbacks.onConfirmSuccess();
                } else {
                    showError(this.dom.confirmButton, result.errors[0].message);
                }
            });
        });
    };

    kill = () => {
        removeAllEvents([this.dom.confirmButton, this.dom.cancelButton]);
        this.callbacks.onCancelClick();
    };

    initialize() {
        const overviewHtml = confirmAddMarkup(
            this.texts,
            this.errorMessages,
            this.data
        );

        emptyElement(this.dom.membershipContent);
        appendElement(overviewHtml, this.dom.membershipContent);

        this.dom.confirmButton =
            this.dom.container.querySelector(".button--confirm");

        addEvent(this.dom.confirmButton, "click", this.addDependent);

        this.dom.cancelButton =
            this.dom.container.querySelector(".button--cancel");
        addEvent(this.dom.cancelButton, "click", this.kill);
    }
}
