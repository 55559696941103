import { ParallaxController } from "parallax-controller";
import { hasClass } from "~/foundation/Dom/classList";

export function setupParallaxElement(parallaxElement) {
    const scrollContainer = document.querySelector("window");
    const controller = ParallaxController.init({ scrollContainer });

    if (hasClass(parallaxElement, "parallax--article")) {
        controller.createElement({
            el: parallaxElement,
            props: {
                opacity: [1, 0],
                startScroll: 0,
                endScroll: 50
            }
        });
    }

    if (hasClass(parallaxElement, "parallax--top-story")) {
        controller.createElement({
            el: parallaxElement,
            props: {
                translateY: ["0px", "100px"],
                opacity: [1, 0],
                startScroll: 100,
                endScroll: 400
            }
        });
    }
}
