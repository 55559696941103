import { onReady } from "~/foundation/Events/onReady";
import {
    Membership,
    membershipStartPages
} from "~/feature/Membership/membership";
import { addEvent } from "~/foundation/Events/events";

export class HeaderAvatar {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.loginButton = this.dom.container.querySelector(
            "[data-status='login']"
        );

        this.dom.profileButton = this.dom.container.querySelector(
            "[data-status='profile']"
        );

        onReady(() => this.initialize());
    }

    logIn = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.LOGIN);
        }
    };

    showProfile = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.PROFILE);
        }
    };

    initialize() {
        addEvent(this.dom.loginButton, "click", () => {
            this.logIn();
        });

        addEvent(this.dom.profileButton, "click", () => {
            this.showProfile();
        });
    }
}
