import { appendElement } from "~/foundation/Dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { forgotPasswordConfirmationMarkup } from "./markup";
import { fetcher } from "~/foundation/Api";

export class ForgotPasswordConfirmation {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;

        this.resendForgotPasswordApiUrl = config.resendForgotPasswordApiUrl;

        this.callbacks = {
            onResendEmailSuccess: config.onResendEmailSuccess
        };

        this.initialize();
    }

    initialize() {
        const confirmationHtml = forgotPasswordConfirmationMarkup(this.texts);

        appendElement(confirmationHtml, this.dom.membershipContent);
        this.dom.membershipContent.className =
            "membership__content membership__content--forgot-password-confirmation";
        this.dom.resendEmailButton = this.dom.membershipContent.querySelector(
            ".membership-footer .button-link"
        );

        addEvent(this.dom.resendEmailButton, "click", () => {
            fetcher(this.resendForgotPasswordApiUrl, "POST", {
                email: this.user.email
            }).then(() => {
                this.callbacks.onResendEmailSuccess();
                removeAllEvents(this.dom.resendEmailButton);
            });
        });
    }
}
