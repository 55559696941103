import {
    appendElement,
    parseHTML,
    emptyElement
} from "~/foundation/Dom/elementManipulation";
import { loaderMarkup } from "./loaderMarkup";

/**
 * Add loader
 *
 * @param {Node} parentElement - The parent element the loader is added to
 * @param {Object} options - Object of options
 * @param {Node} [options.wrapperElement=null] - An element that should be wrapped around the loader but inside the parent
 * @param {boolean} [options.replace=false] - If the loader should replace the content of the parent element
 */
export const addLoader = (
    parentElement,
    options = { wrapperElement: null, replace: false }
) => {
    const { wrapperElement, replace } = options;

    if (replace) {
        emptyElement(parentElement);
    }

    let loaderElement = loaderMarkup;

    if (wrapperElement) {
        wrapperElement.innerHTML = loaderMarkup;
        loaderElement = wrapperElement;
    }

    appendElement(parseHTML(loaderElement), parentElement);
};
