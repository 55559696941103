/**
 * Check if **user agent** is Mobile, Android or Blackberry, and not running on platform Windows, Mac or Linux.
 *
 * @module utils/isMobile
 * @author Casper Andersen <casper.andersen@akqa.com>
 *
 * @example
 * import { isMobile } from './utils/isMobile';
 * if (isMobile) {
 *     // Take some mobileOnly action...
 * }
 *
 */

/**
 *
 * @type {Boolean}
 */
export function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}