import { onReady } from "~/foundation/Events/onReady";
import Flickity from "flickity";

export class RelatedArticles {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.articleSlider = this.dom.container.querySelector(
            ".related-articles__carousel"
        );
        this.dom.articleSliderElements =
            this.dom.articleSlider.querySelectorAll(".gallery-cell");

        onReady(() => this.initialize());
    }

    multipleElementsLayout = () => {
        return this.dom.articleSliderElements.length > 1;
    };

    initialize() {
        const articleFlickity = {
            pageDots: this.multipleElementsLayout(),
            prevNextButtons: this.multipleElementsLayout(),
            freeScroll: true,
            wrapAround: this.multipleElementsLayout(),
            cellAlign: this.multipleElementsLayout() ? "left" : "center",
            lazyLoad: true,
            on: {
                dragStart: () => {
                    this.dom.container.classList.add("translating");
                },
                dragEnd: () => {
                    this.dom.container.classList.remove("translating");
                },
                settle: () => {
                    this.dom.container.classList.remove("translating");
                }
            }
        };

        // eslint-disable-next-line no-unused-vars
        const articleSlider = new Flickity(
            this.dom.articleSlider,
            articleFlickity
        );
    }
}
