import { buildRadioInput } from "~/feature/GreetingCard/greeting/inputs/buildRadioInput";

const stepTwoTemplate = ({ classes, sections }) => {
    const errorMessageTarget = `${classes.rootClass}__error-message-step-2`;

    return `
        <div class="${classes.rootClass}__header">
            <h2 class="${classes.rootClass}__title">${sections.title}</h2>
            <p class="${classes.rootClass}__manchet">${sections.description}</p>
        </div>
        <div class="${classes.rootClass}__section">
            <div>
                <ul class="greeting__radio-list">
                    ${buildRadioInput(sections.fields[0], errorMessageTarget)}
                </ul>
                <p class="${errorMessageTarget}"></p>
            </div>
        </div>
    `;
};

export default stepTwoTemplate;
