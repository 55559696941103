import { onReady } from "~/foundation/Events/onReady";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { addClass, hasClass, removeClass } from "~/foundation/Dom/classList";
import { disableScrollLock, enableScrollLock } from "~/foundation/Dom/scrollLock";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
import { forEach } from "~/foundation/Helpers/forEach";
import { SLOW } from "~/foundation/Constants/durations";
import anime from "animejs";
import { getParameter } from "~/foundation/Helpers/queryString";
import {
    Membership,
    membershipStartPages
} from "~/feature/Membership/membership";
import {
    onClickOutside,
    removeOnClickOutside
} from "~/foundation/Events/onClickOutside";
import { closestParent } from "~/foundation/Dom/parents";
import { isRtl } from "~/foundation/Helpers/isRtl";

const waveDuration = 500;
const waveEasing = "linear";
const waveStart =
    "M0,0 L10,0 C10,105.986036 10,210.607972 10,313.865807 C10,468.752559 10,538.081976 10,603.74187 C10,669.401764 10,755.03975 10,821.648704 C10,866.054674 10,927.171773 10,1005 L0,1005 L0,0 Z";

const waveMiddle =
    "M0,0 L187,0 C145.137023,108.287035 129.797864,212.908971 140.982524,313.865807 C157.759513,465.301061 179.997341,538.394809 179.997341,603.74187 C179.997341,669.088931 141.263881,752.537628 127.977585,821.648704 C119.120054,867.722755 125.454491,928.839854 146.980896,1005 L0,1005 L0,0 Z";

const waveEnd =
    "M0,0 L375,0 C375,105.986036 375,210.607972 375,313.865807 C375,468.752559 375,538.081976 375,603.74187 C375,669.401764 375,755.03975 375,821.648704 C375,866.054674 375,927.171773 375,1005 L0,1005 L0,0 Z";

export class Navigation {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        // Global classes
        this.activeClasses = {
            activeMainClass: "main-container--show-nav",
            activeCaretClass: "nav__caret--active",
            openCaretClass: "nav__caret--open"
        };

        this.isActive = false;

        onReady(() => this.initialize());
    }

    kill() {
        removeAllEvents(this.dom.navigationButton);
        removeAllEvents(this.dom.navigationCloseButton);
        removeAllEvents(this.dom.caretItems);

        if (this.dom.subCarets.length) {
            removeAllEvents(this.dom.subCarets);
        }

        if (this.dom.levelBackButtons.length) {
            removeAllEvents(this.dom.levelBackButtons);
        }

        if (this.dom.logInButton) {
            removeAllEvents(this.dom.logInButton);
        }

        if (this.dom.signUpButton) {
            removeAllEvents(this.dom.signUpButton);
        }

        if (this.dom.profileButton) {
            removeAllEvents(this.dom.profileButton);
        }
    }

    toggleNavigation = () => {
        if (!this.isActive) {
            // Adding a clickOutside handler when opening the navigation
            onClickOutside(
                this.dom.container,
                ({ target }) => {
                    // Only close when clicking on overlay and not popups like the cookie banner
                    if (target.tagName === "BODY") {
                        this.closeNavigation();
                    }
                },
                window,
                false
            );
            this.openNavigation();
        } else {
            this.closeNavigation();
        }
    };

    openNavigation = () => {
        this.isActive = true;
        this.dom.mainContentChildren[0].classList.add("no-transition");

        removeClass(this.dom.container, "nav--disabled");

        enableScrollLock();

        addClass(this.dom.mainContent, this.activeClasses.activeMainClass);

        anime.remove(this.dom.wave);
        anime.remove(this.dom.navigationCloseButton);
        anime.remove(this.dom.caretItems);
        anime.remove(this.dom.subCarets);
        anime.remove(this.dom.levelBackButtons);
        anime.remove(this.dom.buttons);
        anime.remove(this.dom.mainContentChildren);

        if (this.dom.activeSubNav) {
            this.setActiveLevel(this.dom.activeNav);
        } else {
            forEach(this.dom.firstLevelCaretItems, caretItem => {
                caretItem.style.display = "block";
            });
        }

        // Animate in wave svg and move main content
        anime
            .timeline({
                complete: () => {
                    addClass(this.dom.container, "nav--open");
                    // When wave svg and content has animated, animated rest of menu
                    anime
                        .timeline({
                            easing: STANDARDCUBICBEZIER
                        })
                        .add({
                            targets: this.dom.navigationCloseButton,
                            opacity: [0, 1],
                            duration: 100
                        })
                        .add({
                            targets: this.dom.activeSubNav
                                ? [
                                      this.dom.levelBackButtons,
                                      this.dom.activeNavItems
                                  ]
                                : this.dom.firstLevelCaretItems,
                            left: isRtl ? ["-10rem", 0] : ["10rem", 0],
                            opacity: [0, 1],
                            delay: anime.stagger(50)
                        })
                        .add(
                            {
                                targets: this.dom.buttons,
                                translateY: [20, 0],
                                opacity: [0, 1],
                                delay: anime.stagger(50)
                            },
                            200
                        );
                }
            })
            .add({
                targets: this.dom.wave,
                easing: waveEasing,
                duration: waveDuration,
                d: [
                    {
                        value: [waveStart, waveMiddle]
                    },
                    {
                        value: [waveMiddle, waveEnd]
                    }
                ]
            })
            .add(
                {
                    targets: this.dom.mainContentChildren,
                    translateX: isRtl ? "-300px" : "300px",
                    easing: STANDARDCUBICBEZIER,
                    duration: SLOW
                },
                waveDuration / 2
            );
    };

    closeNavigation = (fast = false) => {
        // Removing the clickOutside handler when closing the navigation just in case...
        removeOnClickOutside(this.dom.container);

        disableScrollLock();
        this.isActive = false;
        anime.remove(this.dom.wave);
        anime.remove(this.dom.navigationCloseButton);
        anime.remove(this.dom.caretItems);
        anime.remove(this.dom.subCarets);
        anime.remove(this.dom.levelBackButtons);
        anime.remove(this.dom.buttons);
        anime.remove(this.dom.mainContentChildren);

        removeClass(this.dom.container, "nav--open");
        removeClass(this.dom.mainContent, this.activeClasses.activeMainClass);

        // Animate out wave svg and move main content back
        anime
            .timeline({
                complete: () => {
                    this.dom.mainContentChildren[0].classList.remove(
                        "no-transition"
                    );
                    this.dom.mainContentChildren[0].removeAttribute("style");
                }
            })
            .add({
                targets: this.dom.mainContentChildren,
                translateX: "0%",
                easing: STANDARDCUBICBEZIER,
                duration: !fast ? SLOW : 0
            })
            .add(
                {
                    targets: this.dom.navigationCloseButton,
                    opacity: [1, 0],
                    duration: !fast ? 100 : 0
                },
                0
            )
            .add(
                {
                    targets: this.dom.levelBackButtons,
                    opacity: 0,
                    duration: !fast ? 100 : 0
                },
                0
            )
            .add(
                {
                    targets: [this.dom.caretItems, this.dom.subCarets],
                    opacity: 0,
                    duration: !fast ? 100 : 0
                },
                0
            )
            .add(
                {
                    targets: this.dom.buttons,
                    opacity: [1, 0],
                    translateY: 20,
                    duration: !fast ? 100 : 0
                },
                0
            )
            .add(
                {
                    targets: this.dom.wave,
                    easing: waveEasing,
                    duration: !fast ? waveDuration : 0,
                    d: [
                        {
                            value: [waveEnd, waveMiddle]
                        },
                        {
                            value: [waveMiddle, waveStart]
                        }
                    ]
                },
                !fast ? waveDuration / 4 : 0
            );
    };

    setActiveLevel = activeNode => {
        let currentSubmenu = activeNode.nextElementSibling;

        if (hasClass(activeNode, "nav__caret--sub")) {
            currentSubmenu = closestParent(".nav__sub", activeNode);
        }

        this.dom.activeNavItems =
            currentSubmenu.querySelectorAll(".nav__caret--sub");

        currentSubmenu.style.left = 0;
        currentSubmenu.style.display = "block";
        this.dom.levelBackButtons.style.left = 0;
        this.dom.levelBackButtons.style.opacity = 0;

        addClass(currentSubmenu, this.activeClasses.openCaretClass);

        forEach(this.dom.firstLevelCaretItems, caretItem => {
            caretItem.style.left = isRtl ? "100%" : "-100%";
            caretItem.style.display = "none";
        });

        forEach(this.dom.activeNavItems, subCaretItem => {
            subCaretItem.style.left = "0";
        });
    };

    navigateNextLevel = node => {
        addClass(this.dom.container, "nav--disabled");

        const currentCaret = closestParent(".nav__caret", node);
        const currentSubmenu = currentCaret.nextElementSibling;
        const currentSubmenuItems =
            currentSubmenu.querySelectorAll(".nav__caret--sub");

        // Create a timeline with default parameters
        const tl = anime.timeline({
            easing: STANDARDCUBICBEZIER
        });

        currentSubmenu.style.left = 0;
        currentSubmenu.style.display = "block";

        tl.add({
            targets: this.dom.firstLevelCaretItems,
            left: isRtl ? "20rem" : "-20rem",
            opacity: 0,
            delay: anime.stagger(75),
            complete: () => {
                forEach(this.dom.firstLevelCaretItems, caretItem => {
                    caretItem.style.left = isRtl ? "100%" : "-100%";
                    caretItem.style.display = "none";
                    addClass(currentSubmenu, this.activeClasses.openCaretClass);
                });

                removeClass(this.dom.container, "nav--disabled");
            }
        });

        tl.add(
            {
                targets: [this.dom.levelBackButtons, currentSubmenuItems],
                left: isRtl ? ["-20rem", 0] : ["20rem", 0],
                opacity: [0, 1],
                delay: anime.stagger(75)
            },
            150 * Number(this.dom.firstLevelCaretItems.length)
        );
    };

    navigatePrevLevel = () => {
        addClass(this.dom.container, "nav--disabled");

        const currentSubmenu = this.dom.container.querySelector(
            ".nav__sub.nav__caret--open"
        );
        const currentSubmenuItems =
            currentSubmenu.querySelectorAll(".nav__caret--sub");

        // Create a timeline with default parameters
        const tl = anime.timeline({
            easing: STANDARDCUBICBEZIER
        });

        forEach(this.dom.firstLevelCaretItems, caretItem => {
            caretItem.style.display = "block";
        });

        tl.add({
            targets: [this.dom.levelBackButtons, currentSubmenuItems],
            left: isRtl ? "-20rem" : "20rem",
            opacity: 0,
            delay: anime.stagger(75),
            complete: () => {
                currentSubmenu.style.left = isRtl ? "-100%" : "100%";
                currentSubmenu.style.display = "none";
                this.dom.levelBackButtons.style.left = isRtl ? "-100%" : "100%";

                removeClass(this.dom.container, "nav--disabled");
            }
        });

        tl.add(
            {
                targets: this.dom.firstLevelCaretItems,
                left: isRtl ? ["20rem", 0] : ["-20rem", 0],
                opacity: [0, 1],
                delay: anime.stagger(75)
            },
            150 * Number(currentSubmenuItems.length)
        );

        removeClass(currentSubmenu, this.activeClasses.openCaretClass);
    };

    signUp = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            this.toggleNavigation();
            new Membership(membershipElement, membershipStartPages.SIGNUP);
        }
    };

    logIn = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            this.toggleNavigation();
            new Membership(membershipElement, membershipStartPages.LOGIN);
        }
    };

    profile = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            this.toggleNavigation();
            new Membership(membershipElement, membershipStartPages.PROFILE);
        }
    };

    initialize() {
        this.dom.navigationButton =
            document.querySelector(".toggle-navigation");

        this.dom.navigationCloseButton =
            this.dom.container.querySelector(".nav__close");

        this.dom.activeNav =
            this.dom.container.querySelector(".nav__caret--active") ||
            undefined;

        this.dom.activeSubNav =
            this.dom.container.querySelector(
                ".nav__caret--sub.nav__caret--active"
            ) || undefined;

        this.dom.navList = this.dom.container.querySelector(".nav__list");

        this.dom.subCarets =
            this.dom.container.querySelectorAll(".nav__caret--sub") ||
            undefined;

        this.dom.caretItems = this.dom.container.querySelectorAll(
            ".nav__caret:not(.nav__caret--link)"
        );

        this.dom.firstLevelCaretItems = this.dom.container.querySelectorAll(
            ".nav__caret:not(.nav__caret--sub)"
        );

        this.dom.levelBackButtons =
            this.dom.container.querySelector(".nav__level-back");

        this.dom.mainContent = document.body.querySelector(".main-container");

        this.dom.mainContentChildren =
            this.dom.mainContent.querySelectorAll("main >*");

        this.dom.wave = this.dom.container.querySelector(".nav__svg path");

        this.dom.buttons = this.dom.container.querySelectorAll(
            ".nav__buttons button"
        );

        this.dom.logInButton = this.dom.container.querySelector(".login");

        this.dom.signUpButton = this.dom.container.querySelector(".signup");

        this.dom.profileButton = this.dom.container.querySelector(".profile");

        addEvent(this.dom.navigationButton, "click", this.toggleNavigation);
        addEvent(
            this.dom.navigationCloseButton,
            "click",
            this.toggleNavigation
        );
        addEvent(this.dom.caretItems, "click", event => {
            this.navigateNextLevel(event.target);
        });

        addEvent(this.dom.levelBackButtons, "click", this.navigatePrevLevel);

        if (this.dom.logInButton) {
            addEvent(this.dom.logInButton, "click", this.logIn);
        }

        if (this.dom.signUpButton) {
            addEvent(this.dom.signUpButton, "click", this.signUp);
        }

        if (this.dom.profileButton) {
            addEvent(this.dom.profileButton, "click", this.profile);
        }

        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );

        if (!membershipElement) return;

        this.showSignup =
            membershipElement.getAttribute("data-signup") === "true";
        this.showSelectTopics =
            membershipElement.getAttribute("data-select-topics") === "true";
        this.showResetPassword =
            membershipElement.getAttribute("data-reset-password") === "true";

        if (this.showSelectTopics) {
            new Membership(
                membershipElement,
                membershipStartPages.SELECT_TOPICS
            );
        } else if (this.showResetPassword) {
            new Membership(
                membershipElement,
                membershipStartPages.RESET_PASSWORD
            );
        } else if (this.showSignup) {
            new Membership(membershipElement, membershipStartPages.SIGNUP);
        }

        // We make the check for deeplink for Aramco users (IsInternal)
        // If the deeplink containers returnUrl, we prompt them with Login Overlay
        const urlParameter = getParameter("returnUrl", window.location.href) || getParameter("ReturnUrl", window.location.href);
        if (urlParameter) {
            this.logIn();
        } else {
            return;
        }
    }
}
