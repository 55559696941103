const rotationIcon = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2169_36138)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6514 2.65441C19.0474 3.13346 21.9425 4.83275 23.4627 7.94026L19.8557 6.82529L19.3591 8.65953L24.5813 10.3283L24.5792 10.3369L24.9417 10.4435L26.4102 10.9127L26.5085 10.4424L28.228 3.4698L26.4048 2.93377L25.3439 7.236C23.5518 3.40558 20.0495 1.36287 18.3006 0.762695L17.6514 2.65441ZM4 27.9999H13H15V25.9999V7.99987V5.99987L13 5.99987H4H2V7.99987V25.9999V27.9999L4 27.9999ZM4 25.9999L13 25.9999L13 7.99987L4 7.99987L4 25.9999ZM28 27.9999L17 27.9999V25.9999H26V16.9999L17 16.9999V14.9999L28 14.9999V16.9999V25.9999V27.9999Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2169_36138">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>                
`;

export default rotationIcon;
