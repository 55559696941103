import { parseHTML } from "~/foundation/Dom/elementManipulation";

/**
 * Building the markup for the personal details' section of the application overview.
 *
 * @param {json} eventApplicationModel - JSON model holding the data on the volunteer
 * @param {json} texts - JSON model holding the generic texts such as translations, headers and such
 * @returns {string} - string containing HTML to be parsed as HTML
 */
const buildPersonalDetails = (eventApplicationModel, texts) => {
    return `
    <div class="applicant-overview__details-section">
            <h3>${texts.personalDetailsTitle}</h3>
            <div class="applicant-overview__simple-table">
                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.nameLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.firstName} ${eventApplicationModel.lastName}
                    </div>
                </div>

                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.ageLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.ageInterval}
                    </div>
                </div>

                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.genderLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.gender}
                    </div>
                </div>

                <div class="applicant-overview__table-row margin__bottom--sm">
                    <div class="applicant-overview__table-key">
                        ${texts.locationLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.region}
                    </div>
                </div>

                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.emailLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.email}
                    </div>
                </div>

                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.phoneLabel}:
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.phone}
                    </div>
                </div>
            </div>
        </div>
    `;
};

/**
 * Building the markup for the actual event details section provided by the volunteer when signing up of the application overview.
 *
 * @param {json} eventApplicationModel - JSON model holding the data on the volunteer
 * @param {json} texts - JSON model holding the generic texts such as translations, headers and such
 * @returns {string} - string containing HTML to be parsed as HTML
 */
const buildApplicationDetails = (eventApplicationModel, texts) => {
    return `
        <div class="applicant-overview__details-section">
            <h3>${texts.applicationTitle}</h3>
            <div class="applicant-overview__details-label">
                ${texts.motivationLabel}
            </div>
            <div class="applicant-overview__details-value">
                <ul>
                    ${eventApplicationModel.motivations
                        .map(motivation => {
                            return `<li>${motivation}</li>`;
                        })
                        .join("")}
                    ${
                        eventApplicationModel.motivationOther
                            ? eventApplicationModel.motivationOther
                            : ""
                    }
                </ul>
            </div>
             <div class="applicant-overview__details-label">
                ${texts.businessLineLabel}
            </div>
            <div class="applicant-overview__details-value">
                ${eventApplicationModel.businessLine}
            </div>
             <div class="applicant-overview__details-label">
                ${texts.languageLabel}
            </div>
            <div class="applicant-overview__simple-table">
                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.languageArabicLabel}
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.arabicLanguageLevel}
                    </div>
                </div>
                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-key">
                        ${texts.languageEnglishLabel}
                    </div>
                    <div class="applicant-overview__table-value">
                        ${eventApplicationModel.englishLanguageLevel}
                    </div>
                </div>
            </div>
            ${
                eventApplicationModel.notes
                    ? `<div class="applicant-overview__details-label">
            ${texts.noteLabel}
        </div>
        <div class="applicant-overview__details-value">
            ${eventApplicationModel.notes}
        </div>`
                    : ""
            }
        </div>
    `;
};

/**
 * Building the markup for the the previous experience section of the application overview.
 *
 * @param {json} previousEvents - JSON model holding the data on the volunteers' previous events
 * @param {json} texts - JSON model holding the generic texts such as translations, headers and such
 * @returns {string} - string containing HTML to be parsed as HTML
 */
/*const buildPreviousExperience = (previousEvents, texts) => {
    return `
         <div class="applicant-overview__details-section">
            <h3>${texts.previousEventsTitle}</h3>
            <div class="applicant-overview__table">
                <div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-header applicant-overview__table-td--event">
                        ${texts.eventsLabel}
                    </div>
                    <div class="applicant-overview__table-header applicant-overview__table-td--date">
                        [DATE]
                    </div>
                    <div class="applicant-overview__table-header applicant-overview__table-td--role">
                        ${texts.roleLabel}
                    </div>
                    <div class="applicant-overview__table-header applicant-overview__table-td--hours">
                        ${texts.hoursLabel}
                    </div>
                </div>
                ${previousEvents.results.map(event => {
                    return `<div class="applicant-overview__table-row">
                    <div class="applicant-overview__table-td applicant-overview__table-td--event">
                        ${event}
                    </div>
                    <div class="applicant-overview__table-td applicant-overview__table-td--date">
                        ${event}
                    </div>
                    <div class="applicant-overview__table-td applicant-overview__table-td--role">
                        ${event}
                    </div>
                    <div class="applicant-overview__table-td applicant-overview__table-td--hours">
                        ${event}
                    </div>
                </div>`;
                })}
            </div>
        </div>
    `;
};*/

/**
 * Exported function that returns the full application overview from data parsed as parameters
 *
 * @param {json} data - The root json object from the API response
 * @param {string} eventTitle - The title of the actual event parsed from the initiator class
 * @param {boolean} isVolunteer - Boolean representing what tab we are supposed to render the HTML in
 * @returns {html} - String parsed as HTML containing the above functions
 */
export const applicantOverviewMarkup = (
    data,
    eventTitle,
    isVolunteer = false
) => {
    const { api, eventApplicationModel, texts } = data;
    return parseHTML(
        `
<div class="applicant-overview" data-user-id="${eventApplicationModel.id}">
    <div class="applicant-overview__inner">
        <header class="applicant-overview__header">
            <button class="button applicant-overview__close-button"></button>
            <h4>${texts.title.replace("{eventName}", "")} ${eventTitle}</h4>
            <h2>${eventApplicationModel.firstName} ${
            eventApplicationModel.lastName
        }</h2>
            <div class="applicant-overview__previous-data">
                <div class="applicant-overview__label-wrapper">
                    <label class="applicant-overview__label">${
                        texts.joinedLabel
                    }</label>
                        <p class="applicant-overview__previous-value">${
                            eventApplicationModel.joinedEvents
                        }</p>
                    <label class="applicant-overview__label">${
                        texts.eventsLabel
                    }</label>
                </div>
                <div class="applicant-overview__label-wrapper">
                    <label class="applicant-overview__label">${
                        texts.volunteeredLabel
                    }</label>
                        <p class="applicant-overview__previous-value">${
                            eventApplicationModel.volunteeredCount
                        }</p>
                    <label class="applicant-overview__label">${
                        texts.hoursLabel
                    }</label>
                </div>
            </div>
        </header>
        <div class="applicant-overview__content">
            ${buildPersonalDetails(eventApplicationModel, texts)}
            ${buildApplicationDetails(eventApplicationModel, texts)}
        </div>
        <footer class="applicant-overview__buttons">
            ${
                isVolunteer
                    ? `<button class="button button--secondary button--volunteer-green applicant-overview__register-attendance" ${
                          eventApplicationModel.attendance ? "disabled" : ""
                      } data-endpoint="${api.registerAttendanceEndPoint}">${
                          texts.registerAttendanceLabel
                      }</button>
                <button class="button button--secondary button--volunteer-green applicant-overview__assign-rewards" ${
                    eventApplicationModel.certificate ? "disabled" : ""
                } data-endpoint="${api.assignRewardsEndPoint}">${
                          texts.assignAwardsLabel
                      }</button>`
                    : `
                    <button class="button button--secondary button--volunteer-green applicant-overview__move-applicant" data-endpoint="${api.approveApplicantEndPoint}">${texts.approveApplicantButtonLabel}</button>
                `
            }
            <a class="button button--secondary button--secondary-blue applicant-overview__generate-email" href="mailto:?bcc=${
                eventApplicationModel.email
            }" >${texts.generateEmailLabel}</a>
            ${
                !isVolunteer
                    ? `<button class="button applicant-overview__delete-applicant" data-delete-endpoint="${api.deleteApplicantsEndPoint}" data-delete-confirm-text="${texts.deleteConfirmText}" data-no-button-label="${texts.noButtonLabel}" data-yes-button-label="${texts.yesButtonLabel}"><span>${texts.deleteApplicantButtonLabel}</span></button>`
                    : ""
            }
            ${
                isVolunteer && eventApplicationModel.canReject
                    ? `<button class="button applicant-overview__reject-applicant" data-button="reject" data-endpoint="${api.rejectApplicantsEndPoint}"><span>${texts.rejectApplicantButtonLabel}</span></button>`
                    : ""
            }
        </footer>
    </div>
</div>`
    );
};
