import { fetcher } from "~/foundation/Api";

export class PhotoCompetitionService {
    contextItemId = null;
    orderBy = null; // "created" | "popular"
    orderByDescending = true;
    filterBy = "none"; // "none" | "mylikes" | "myphotos"
    pageSize = 20;
    onGalleryItemFetch = null;

    page = 1;
    total = 0;

    constructor({
        competitionId,
        contextItemId,
        pageSize = 20,
        orderBy = "created",
        orderByDescending = true,
        filterBy = "none",
        onGalleryItemFetch,
        loaderElement,
    }) {
        this.competitionId = competitionId;
        this.contextItemId = contextItemId;
        this.orderBy = orderBy;
        this.orderByDescending = orderByDescending;
        this.filterBy = filterBy;
        this.pageSize = pageSize;
        this.onGalleryItemFetch = onGalleryItemFetch;
        this.loaderElement = loaderElement;
    }

    async getGalleryItems() {
        if (this.loaderElement) {
            this.loaderElement.style.display = "block";
        }

        try {
            const data = await fetcher(
                `/api/v1/photo-competition/${this.contextItemId}/gallery-items?page=${this.page}&pageSize=${this.pageSize}&orderBy=${this.orderBy}&orderByDescending=${this.orderByDescending}&filterBy=${this.filterBy}`
            );

            if (this.loaderElement) {
                this.loaderElement.style.display = "none";
            }

            this.total = data.total;
            this.userGalleryItemsCount = data.myPhotosCount;

            if (typeof this.onGalleryItemFetch === "function") {
                this.onGalleryItemFetch(data);
            }

            return data.galleryItems;
        } catch (e) {
            console.error("getGalleryItems api failed", e);
            if (this.loaderElement) {
                this.loaderElement.style.display = "none";
            }
        }
    }

    async getCompetition() {
        try {
            return await fetcher(
                `/api/v1/photo-competition/${this.contextItemId}`
            );
        } catch (e) {
            console.error("getCompetition api failed", e);
        }
    }

    async getUserGalleryItemsCount() {
        const userGalleryItemsCount = await this.getGalleryItems();
        return userGalleryItemsCount;
    }

    async loadMore() {
        this.page = this.page + 1;
        const galleryItems = await this.getGalleryItems();
        return galleryItems;
    }

    async setOrdering(orderBy, filterBy = null) {
        this.orderBy = orderBy;
        this.page = 1;
        this.filterBy = filterBy || "none";

        const galleryItems = await this.getGalleryItems();
        return galleryItems;
    }

    async likePhoto(photoId) {
        return await fetcher(
            `/api/v1/photo-competition/${this.contextItemId}/like/${photoId}`,
            "POST"
        );
    }

    async removeLikePhoto(photoId) {
        return await fetcher(
            `/api/v1/photo-competition/${this.contextItemId}/like/${photoId}`,
            "DELETE"
        );
    }

    async clearCache() {
        return await fetcher(
            `/api/v1/photo-competition/${this.contextItemId}/gallery-items/cache`,
            "DELETE"
        );
    }
}
