import { buildTextareaInput } from "~/feature/Volunteer/signup/inputs/buildTextareaInput";
import { delegateEvent } from "~/foundation/Events/events";
import { toggleClass, hasClass } from "~/foundation/Dom/classList";

export const createMarkup = (option, field, errorMessageTargetClass) => {
    return `
        <li>
            <label>
                <input type="checkbox"
                    name="${field.key}"
                    value="${option.value}"
                    data-error-message="${field.defaultErrorMessage}"
                    data-error-container="${errorMessageTargetClass}"
                    ${field.readOnly ? ` readonly` : ``}
                    ${field.isRequired ? ` required` : ``}
                     ${
                         option.textArea
                             ? `class="volunteer-signup__textarea-trigger"`
                             : ``
                     }
                />
                <span>${option.label}</span>
            </label>
        </li>
        ${
            option.textArea
                ? `
                    <li class="volunteer-signup__textarea-group volunteer-signup__textarea-group--checkbox">
                        ${buildTextareaInput(option.textArea)}
                    </li>
                `
                : ""
        }
    `;
};

export const buildCheckboxInput = (field, errorMessageTargetClass) => {
    let textAreaOpen = false;

    // eslint-disable-next-line no-unused-vars
    delegateEvent(".volunteer-signup__textarea-trigger", "click", e => {
        const currentTextArea = document.querySelector(
            ".volunteer-signup__step:not([hidden]) .volunteer-signup__textarea-group--checkbox"
        );

        textAreaOpen = hasClass(currentTextArea, "open-field");

        toggleClass(currentTextArea, "open-field", !textAreaOpen);

        textAreaOpen = !textAreaOpen;
    });

    return field.options
        .map(option => createMarkup(option, field, errorMessageTargetClass))
        .join("");
};
