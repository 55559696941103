/**
 * Check if **user agent** is IE11
 *
 * @module utils/isIE11
 * @author Bjarni Olsen <bjarni.olsen@akqa.com>
 *
 * @example
 * import { isMobile } from './utils/isMobile';
 * if (isMobile) {
 *     // Take some mobileOnly action...
 * }
 *
 */

/**
 *
 * @type {Boolean}
 */
export const isIE11 = /*@cc_on!@*/ false || !!document.documentMode;
