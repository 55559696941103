import { appendElement } from "~/foundation/Dom/elementManipulation";

import {
    addEventOnce,
    addEvent,
    removeAllEvents
} from "~/foundation/Events/events";
import { loginMarkup } from "./markup";
import {
    infoNotificationMarkup,
    errorNotificationMarkup
} from "~/foundation/Components/notification/notificationMarkup";
import { ToggleVisiblePassword } from "~/foundation/Form/input/toggle-visible-password";
import { showError, removeErrors } from "~/foundation/Form/input/input-errors";
import { fetcher } from "~/foundation/Api";
import { validateEmail } from "~/foundation/Helpers/validate";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { makeButtonLoad } from "~/foundation/Components/button/make-button-load";
import { addLoader } from "~/foundation/Loaders/loader";

export class Login {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.loginApiUrl = config.loginApiUrl;
        this.errorMessages = config.errorMessages;
        this.data = config.data;

        this.callbacks = {
            onCreateAccountClick: config.onCreateAccountClick,
            onForgotPasswordClick: config.onForgotPasswordClick,
            onLoginSuccess: config.onLoginSuccess
        };

        this.initialize();
    }

    validateAndLogin = () => {
        if (this.dom.emailInput.value === "") {
            showError(this.dom.emailInput, this.errorMessages.emailRequired);
            return;
        }

        if (!validateEmail(this.dom.emailInput.value)) {
            showError(this.dom.emailInput, this.errorMessages.invalideEmail);
            return;
        }

        if (this.dom.passwordInput.value === "") {
            showError(
                this.dom.passwordInput,
                this.errorMessages.passwordRequired
            );
            return;
        }

        removeErrors();

        const buttonLoader = new makeButtonLoad(
            this.dom.continueButton,
            this.texts.loading
        );
        buttonLoader.load();

        fetcher(`/${isRtl ? "ar" : "en"}${this.loginApiUrl}`, "POST", {
            email: this.dom.emailInput.value,
            password: this.dom.passwordInput.value
        }).then(result => {
            if (result) {
                const { success } = result;
                buttonLoader.finish(true).then(() => {
                    if (success) {
                        this.callbacks.onLoginSuccess();
                        addLoader(this.dom.middle, { replace: true });
                    } else {
                        // Append the errorNotificationMarkup with error data
                        const notificationHtml =
                            errorNotificationMarkup(result);
                        appendElement(
                            notificationHtml,
                            this.dom.membershipContent
                        );
                    }
                });
            } else {
                // Append the errorNotificationMarkup with error data
                const notificationHtml = errorNotificationMarkup(result);
                appendElement(notificationHtml, this.dom.membershipContent);
            }
        });
    };

    kill() {
        removeAllEvents(this.dom.continueButton);
        this.toggleVisisblePassword.kill();
    }

    createAccountLink = () => {
        this.kill();
        this.callbacks.onCreateAccountClick();
    };

    forgotPasswordLink = () => {
        this.kill();
        this.callbacks.onForgotPasswordClick();
    };

    initialize() {
        const loginHtml = loginMarkup(this.texts);

        appendElement(loginHtml, this.dom.membershipContent);

        this.dom.membershipContent.className =
            "membership__content membership__content--login-email-password";
        this.dom.middle =
            this.dom.membershipContent.querySelector(".membership-middle");
        this.dom.continueButton = this.dom.membershipContent.querySelector(
            ".membership-middle .button"
        );
        this.dom.emailInput =
            this.dom.membershipContent.querySelector("#email");
        this.dom.passwordInput =
            this.dom.membershipContent.querySelector("#password");
        const passwordToggleButton = this.dom.membershipContent.querySelector(
            ".toggle-visible-password"
        );
        this.toggleVisisblePassword = new ToggleVisiblePassword(
            passwordToggleButton
        );
        const createAccountButton =
            this.dom.membershipContent.querySelector(".create-account");
        const forgotPasswordButton =
            this.dom.membershipContent.querySelector(".forgot-password");

        if (this.data.email) {
            this.dom.emailInput.value = this.data.email;
            const notificationHtml = infoNotificationMarkup(this.data.message);
            appendElement(notificationHtml, this.dom.membershipContent);
        }

        addEvent(this.dom.continueButton, "click", this.validateAndLogin);
        addEventOnce(createAccountButton, "click", this.createAccountLink);
        addEventOnce(forgotPasswordButton, "click", this.forgotPasswordLink);
    }
}
