import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";
import { addClass, hasClass, removeClass } from "~/foundation/Dom/classList";
import { createElement } from "~/foundation/Dom/createElement";
import { deleteElement } from "~/foundation/Dom/elementManipulation";
import { addLoader } from "~/foundation/Loaders/loader";

export class TabNavigationIframe {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        onReady(() => this.initialize());
    }

    addLoader = parentAppendingTo => {
        const wrapper = createElement("div", {
            className: "tab-navigation-iframe__loader"
        });

        addLoader(parentAppendingTo, {
            wrapperElement: wrapper
        });
    };

    removeLoader = () => {
        this.dom.loaderElement = this.dom.filters.querySelector(
            ".tab-navigation-iframe__loader"
        );
        deleteElement(this.dom.loaderElement);
    };
    /**
     * Set iframe src
     *
     * @param url
     */
    setIframeUrl(url) {
        //reset iframe src to make room for displaying spinner
        this.dom.tabNavIframe.src = "";
        removeClass(this.dom.tabNavIframe, "is-active");
        this.addLoader(this.dom.filters);
        //set new iframe url
        this.dom.tabNavIframe.src = url;
    }

    /**
     * Load external script to handle iframe height
     */
    loadIframeHeightScript() {
        const euroland_script = document.createElement("script");
        euroland_script.src =
            "https://tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js";
        euroland_script.type = "text/javascript";
        euroland_script.onload = function () {
            // eslint-disable-next-line no-undef
            EurolandToolIntegrationObject.set("euroland-iframe");
        };
        document.head.appendChild(euroland_script);
    }

    initialize() {
        this.dom.tabNavLinks = this.dom.container.querySelectorAll(
            ".tab-navigation-iframe__link"
        );
        this.dom.tabNavIframe = this.dom.container.querySelector(
            ".tab-navigation-iframe__content iframe"
        );
        this.dom.filters = this.dom.container.querySelector(
            ".tab-navigation-iframe__filters"
        );

        this.loadIframeHeightScript();

        addEvent(this.dom.tabNavIframe, "load", e => { // eslint-disable-line
            this.removeLoader();
            addClass(this.dom.tabNavIframe, "is-active");
        });

        addEvent(this.dom.tabNavLinks, "click", e => {
            e.preventDefault();

            if (!hasClass(e.currentTarget, "is-current")) {
                this.setIframeUrl(e.currentTarget.href);

                removeClass(this.dom.tabNavLinks, "is-current");
                addClass(e.currentTarget, "is-current");
            }
        });
    }
}
