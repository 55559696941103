/**
 * Returning the timezone as an IANA time zone identifier
 * Using the Intl.DateTimeFormat().resolvedOptions().timeZone which is NOT supported in IE11
 * and SHOULD be handled with a fallback or similar
 *
 * @module helpers/getLocalTimeZone
 * @author Bjorn Barfoed <bjorn.barfoed@akqa.com>
 *
 * @example
 * import { getLocalTimeZone } from './utils/isMobile';
 *
 * @return
 * {string} containing the IANA time zone identifier (Europe/Copenhagen) or undefined if IE 11
 *
 */

/**
 *
 * @type {string}
 */
export const getLocalTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
