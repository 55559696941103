import { onReady } from "~/foundation/Events/onReady";
import { onScroll, removeScrollCallback } from "~/foundation/Events/onScroll";
import { hasClass, addClass, removeClass } from "~/foundation/Dom/classList";
import { VERYSLOW } from "~/foundation/Constants/durations";
import { isExperienceEditor } from "~/foundation/Helpers/isExperienceEditor";

export class Header {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    settings = {
        isHeaderVisible: true,
        scrollValue: 0,
        hideOffset: 110,
        borderOffset: 150,
        hideClass: "header--hidden",
        headerModeTimeout: undefined,
        isExpanded: true
    };

    kill() {
        removeScrollCallback(window, this.toggleHeaderVisibility);
    }

    expand() {
        if (!this.settings.isExpanded) {
            this.settings.isExpanded = true;

            removeClass(this.dom.container, "header--scrolled");

            if (window.pageYOffset <= this.settings.borderOffset) {
                removeClass(this.dom.container, "header--border-color");
            }
        }
    }

    contract() {
        this.settings.isExpanded = false;

        setTimeout(() => {
            if (window.pageYOffset >= this.settings.borderOffset) {
                addClass(this.dom.container, "header--border-color");
            }
        }, VERYSLOW);

        addClass(this.dom.container, "header--scrolled");
    }

    /**
     * toggler for adding a class and updating a let for the sticky header
     * @private
     */
    toggleHeaderVisibility = () => {
        if (window.pageYOffset <= 0) {
            this.expand();

            if (this.settings.headerModeTimeout) {
                clearTimeout(this.settings.headerModeTimeout);
            }

            if (this.isLightMode) {
                removeClass(this.dom.container, "header--dark");
                addClass(this.dom.container, "header--light");
            }
        }

        // Wait for animation to be finished before changhing headermode. Light to dark
        if (
            window.pageYOffset >= this.settings.hideOffset &&
            window.pageYOffset > this.settings.scrollValue
        ) {
            addClass(this.dom.container, this.settings.hideClass);
            if (this.settings.isExpanded) {
                this.contract();
            }

            if (this.settings.headerModeTimeout) {
                clearTimeout(this.settings.headerModeTimeout);
            }

            this.settings.headerModeTimeout = setTimeout(() => {
                if (this.isLightMode) {
                    addClass(this.dom.container, "header--dark");
                    removeClass(this.dom.container, "header--light");
                }
            }, VERYSLOW);
            this.settings.isHeaderVisible = false;
        } else {
            if (!this.settings.isHeaderVisible) {
                removeClass(this.dom.container, this.settings.hideClass);

                if (this.settings.headerModeTimeout) {
                    clearTimeout(this.settings.headerModeTimeout);
                }

                if (
                    window.pageYOffset < this.settings.borderOffset &&
                    hasClass(this.dom.container, "header--border-color")
                ) {
                    removeClass(this.dom.container, "header--border-color");
                }

                if (
                    this.isLightMode &&
                    hasClass(this.dom.container, "header--light")
                ) {
                    if (this.settings.headerModeTimeout) {
                        clearTimeout(this.settings.headerModeTimeout);
                    }

                    addClass(this.dom.container, "header--dark");
                    removeClass(this.dom.container, "header--light");
                }

                this.settings.isHeaderVisible = true;
            }
        }

        this.settings.scrollValue = window.pageYOffset;
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        if (!isExperienceEditor) {
            onReady(() => this.initialize());
        }
    }

    initialize() {
        this.isLightMode =
            this.dom.container.classList.contains("header--light");

        this.dom.logo = this.dom.container.querySelector(".header__logo");

        this.toggleHeaderVisibility();

        onScroll(window, this.toggleHeaderVisibility);
    }
}
