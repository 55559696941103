import { onReady } from "~/foundation/Events/onReady";
import {
    appendElement,
    emptyElement,
    replaceElement
} from "~/foundation/Dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { getPolls } from "../poll/api-helpers";
import { Poll } from "../poll/poll";
import { pollContainerMarkup } from "./markup/pollContainerMarkup";
import { pollListItemMarkup } from "./markup/pollListItemMarkup";
import { setupInView } from "~/foundation/Dom/inView";
import { addClass, removeClass } from "~/foundation/Dom/classList";
import { LocalDatetime } from "~/foundation/LocalDatetime/local-datetime";

export const pollStatusTypes = {
    ALL: 0,
    OPEN: 1,
    CLOSED: 2
};

export class PollList {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.filterValue = pollStatusTypes.ALL;
        this.loadMoreAmount = parseInt(this.dom.container.dataset.listLength);
        this.loadedAmount = this.loadMoreAmount;
        this.currentCountIndex = 0;
        this.totalPolls = undefined;

        onReady(() => this.initialize());
    }

    loadMore() {
        if (
            this.loadedAmount < this.totalPolls ||
            this.loadedAmount + 1 === this.totalPolls
        ) {
            // Disable button while getting more polls
            this.dom.loadMoreButton.disabled = true;
            this.currentCountIndex =
                this.currentCountIndex + this.loadMoreAmount;
            getPolls(
                this.filterValue,
                this.loadMoreAmount,
                this.currentCountIndex
            ).then(result => {
                const { data, success } = result;
                this.loadedAmount =
                    this.currentCountIndex + this.loadMoreAmount;

                if (success) {
                    // If no more polls remove listeners
                    if (this.loadedAmount >= this.totalPolls) {
                        removeAllEvents(this.dom.loadMoreButton);
                        this.dom.loadMoreButton.style.display = "none";
                    } else {
                        this.dom.loadMoreButton.style.display = "flex";
                        this.dom.loadMoreButton.disabled = false;
                    }

                    data.results.forEach(item => this.loadMarkup(item));

                    this.loadPolls();
                }
            });
        }
    }

    loadMarkup(item) {
        const pollListHtml = pollListItemMarkup(item, this.labels);
        appendElement(pollListHtml, this.dom.pollListContent);

        const inViewClass = ".inview";
        const inViewElements =
            this.dom.pollListContent.querySelector(inViewClass);

        if (inViewElements) {
            setupInView(inViewClass, "inview--active", "show", 0);
        }

        const poolListDate = this.dom.pollListContent.querySelectorAll(
            '[data-async-module="local-datetime"]'
        );

        if (poolListDate.length > 0) {
            poolListDate.forEach(dateItem => {
                new LocalDatetime(dateItem);
            });
        }
    }

    loadPolls() {
        const pollButtons = this.dom.container.querySelectorAll(
            ".poll-list__item button"
        );

        addEvent(pollButtons, "click", event => {
            const id = event.target.dataset.pollId;

            let globalPoll = document.querySelector(".global-poll");
            if (globalPoll) {
                replaceElement(globalPoll, pollContainerMarkup({ id }));
            } else {
                const body = document.querySelector("body");
                appendElement(pollContainerMarkup({ id }), body);
            }

            const poll = document.querySelector(".global-poll");
            new Poll(poll);
        });
    }

    filter(event = null) {
        this.filterValue = event?.target.dataset.value || pollStatusTypes.ALL;

        this.loadedAmount = this.loadMoreAmount;
        this.currentCountIndex = 0;

        getPolls(
            this.filterValue,
            this.currentCountIndex,
            this.loadedAmount
        ).then(result => {
            const { data, success } = result;
            this.totalPolls = data.totalNumber;

            if (this.loadedAmount >= this.totalPolls) {
                this.dom.loadMoreButton.style.display = "none";
                this.dom.loadMoreButton.disabled = true;
            } else if (this.loadedAmount < this.totalPolls) {
                this.dom.loadMoreButton.style.display = "flex";
                this.dom.loadMoreButton.disabled = false;
            }

            // Make sure we only have one event listener
            removeAllEvents(this.dom.loadMoreButton);
            addEvent(
                this.dom.loadMoreButton,
                "click",
                this.loadMore.bind(this)
            );

            if (success) {
                emptyElement(this.dom.pollListContent);
                data.results.forEach(item => this.loadMarkup(item));

                if (event) {
                    removeClass(this.dom.filterItems, "active");
                    addClass(event.target, "active");
                }

                this.loadPolls();
            }
        });
    }

    kill() {
        removeAllEvents(this.dom.loadMoreButton);
    }

    initialize() {
        this.dom.pollListContent = this.dom.container.querySelector(
            ".poll-list__content"
        );
        this.dom.loadMoreButton =
            this.dom.container.querySelector(".load-more-polls");
        this.dom.filterItems = this.dom.container.querySelectorAll(
            ".poll-list__filter-item"
        );

        this.labels = {
            active: this.dom.container.dataset.labelActive,
            clickVote: this.dom.container.dataset.labelClickvote,
            viewResults: this.dom.container.dataset.labelViewresults,
            closed: this.dom.container.dataset.labelClosed,
            votes: this.dom.container.dataset.labelVotes
        };

        addEvent(this.dom.filterItems, "click", event => {
            this.filter(event);
        });

        this.filter();
    }
}
