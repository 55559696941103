import { onReady } from "~/foundation/Events/onReady";
import MiniMasonry from "minimasonry";
import {
    breakpointIndex,
    onWindowResize,
    currentWindowWidth,
} from "~/foundation/Events/onWindowResize";
import { setStyles, resetStyles } from "~/foundation/Dom/setStyles";
import { forEach } from "~/foundation/Helpers/forEach";
import { bootstrappedInstances, counts } from "~/foundation/Bootstrapper";
import { isRtl } from "~/foundation/Helpers/isRtl";

export class ArticleTeaserList {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined,
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.masonry = undefined;

        onReady(() => this.initialize());
    }

    getTranslate3d = (el) => {
        const values = el.style.transform.split(/\w+\(|\);?/);
        if (!values[1] || !values[1].length) {
            return [];
        }

        return values[1].split(/,\s?/g);
    };

    initMasonry = () => {
        const masonryElement = document.querySelector(
            `.masonry-container-${this.dom.masonryId}`
        );

        let masonryGutterX;
        let masonryBaseWidth;

        if (currentWindowWidth >= breakpointIndex.lg) {
            masonryGutterX = 100;
            masonryBaseWidth = 550;
        }

        if (
            currentWindowWidth >= breakpointIndex.md &&
            currentWindowWidth < breakpointIndex.lg
        ) {
            masonryGutterX = 50;
            masonryBaseWidth = 450;
        }

        if (
            currentWindowWidth >= breakpointIndex.sm &&
            currentWindowWidth < breakpointIndex.md
        ) {
            masonryGutterX = 0;
            masonryBaseWidth = 300;
        }

        if (masonryElement) {
            this.masonry = new MiniMasonry({
                container: `.masonry-container-${this.dom.masonryId}`,
                gutterY: 60,
                gutterX: masonryGutterX,
                baseWidth: masonryBaseWidth,
                surroundingGutter: false,
            });

            if (isRtl) {
                this.updateRtlLayout();
            }
        }
    };

    updateTeaserList = () => {
        // Update the masonry list after getting new data
        // Check bootstrappedInstances for article-teaser-list's and updateMasonry for alle of them
        if (
            bootstrappedInstances["article-teaser-list"] &&
            counts["article-teaser-list"]
        ) {
            for (
                let index = 0;
                index <= counts["article-teaser-list"];
                index++
            ) {
                if (index === 0) {
                    // Only update that teaser-list closest to load-more container
                    if (
                        bootstrappedInstances[`article-teaser-list`].dom
                            .container === this.dom.container
                    ) {
                        bootstrappedInstances[
                            `article-teaser-list`
                        ].dom.container.classList.add("updated");
                        bootstrappedInstances[
                            `article-teaser-list`
                        ].updateMasonry();
                    }
                } else {
                    // Only update that teaser-list closest to load-more container
                    if (
                        bootstrappedInstances[`article-teaser-list-${index}`]
                            .dom.container === this.dom.container
                    ) {
                        bootstrappedInstances[
                            `article-teaser-list-${index}`
                        ].dom.container.classList.add("updated");
                        bootstrappedInstances[
                            `article-teaser-list-${index}`
                        ].updateMasonry();
                    }
                }
            }
        } else if (
            bootstrappedInstances["article-teaser-list"] &&
            bootstrappedInstances["article-teaser-list"].dom.container ===
                this.dom.container &&
            !counts["article-teaser-list"]
        ) {
            bootstrappedInstances[`article-teaser-list`].updateMasonry();
        }
    };

    updateRtlLayout = () => {
        // if RTL we loop through the teasers and replace inline style transform with negative number
        forEach(
            this.dom.container.querySelectorAll(".article-teaser"),
            (teaserElement) => {
                const transformNumbers = this.getTranslate3d(teaserElement);
                // get the first number ans set minus in front
                transformNumbers[0] = `-${transformNumbers[0]}`;

                // set the style in the dom
                setStyles(teaserElement, {
                    transform: `translate3d(${transformNumbers.toString()})`,
                });
            }
        );
    };

    // Instead of reloading list - we reload the page on loginsuccess
    // reloadList = () => {
    //     const endpoint = this.dom.container.getAttribute("data-endpoint");
    //     const topicId = this.dom.container.getAttribute("data-topicid");
    //     const publicationId = this.dom.container.getAttribute(
    //         "data-publicationId"
    //     );
    //     const listLength = this.dom.appendList.getAttribute("data-list-length");
    //     const usePublicationMarkup = publicationId || false;

    //     // Fetch the data
    //     fetcher(
    //         `${endpoint}?${topicId ? `topicId=${topicId}&` : ""}${
    //             publicationId ? `publicationId=${publicationId}&` : ""
    //         }startIndex=0&length=${listLength}`
    //     ).then(({ data }) => {
    //         let loadedHtml = [];

    //         // We will load the correct markup based on the dataset set in the view. This needs to be done since it differs.
    //         forEach(data.results, dataObj => {
    //             const html = usePublicationMarkup
    //                 ? publicationMarkup(dataObj)
    //                 : teaserMarkup(dataObj);

    //             loadedHtml = [...loadedHtml, ...html];
    //         });

    //         emptyElement(this.dom.appendList);
    //         appendElement(loadedHtml, this.dom.appendList);

    //         // If we are not loading publications we want to initiate the article teaser class
    //         if (
    //             !usePublicationMarkup &&
    //             currentWindowWidth >= breakpointIndex.lg
    //         ) {
    //             this.updateTeaserList();
    //         }

    //         // Initiating the lazyload and inview within our scope after we appended the data
    //         const inViewClass = ".inview";
    //         const inViewElements = this.dom.appendList.querySelector(
    //             inViewClass
    //         );

    //         if (inViewElements) {
    //             setupInView(inViewClass, "inview--active", "show", 0);
    //         }

    //         const lazyClass = ".lazy";
    //         const lazyloadConfig = {
    //             elements_selector: lazyClass,
    //             class_loading: "lz-loading",
    //             class_loaded: "lz-loaded"
    //         };

    //         // Check if there is any images to lazyLoad before init lazyload
    //         const lazyElements = this.dom.appendList.querySelector(lazyClass);
    //         if (lazyElements) {
    //             new LazyLoad(lazyloadConfig);
    //         }
    //     });
    // };

    killMasonry = () => {
        resetStyles(this.dom.articleTeaser);
        resetStyles(this.dom.articleTeaserList);
    };

    updateMasonry() {
        this.masonry.layout();

        if (isRtl) {
            // If we are using the RTL layout we should maintain it when loading more
            this.updateRtlLayout();
        }
    }

    checkWindowSize = () => {
        if (currentWindowWidth >= breakpointIndex.sm) {
            this.initMasonry();
        } else {
            this.killMasonry();
        }
    };

    initialize() {
        this.dom.articleTeaser =
            this.dom.container.querySelectorAll(".article-teaser");
        this.dom.articleTeaserList = this.dom.container.querySelector(
            ".article-teaser-list"
        );

        this.dom.appendList =
            this.dom.container.querySelector("[data-append-list]");

        this.dom.masonryId = this.dom.container.getAttribute("data-masonryid");

        if (currentWindowWidth >= breakpointIndex.sm) {
            this.initMasonry();
        } else {
            this.killMasonry();
        }

        onWindowResize(this.checkWindowSize);
    }
}
