import { appendElement } from "~/foundation/Dom/elementManipulation";
import { splashMarkup } from "./markup";
import anime from "animejs";

export class Splash {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;

        this.callbacks = {
            onSplashDone: config.onSplashDone
        };

        this.initialize();
    }

    initialize() {
        const splashHtml = splashMarkup();
        appendElement(splashHtml, this.dom.membershipContent);

        const splashDurationElem = document.querySelector(".splash-duration");
        this.dom.membershipContent.className =
            "membership__content membership--signup-splash";

        anime({
            targets: splashDurationElem,
            height: ["0%", "100%"],
            easing: "linear",
            duration: 2000,
            complete: () => {
                this.callbacks.onSplashDone();
            }
        });
    }
}
