const createMarkup = (option, field, errorMessageTargetClass) => {
    const label = option.label ? option.label : field.label;
    const value = option.value ? option.value : field.value;

    return `
        <label for="${field.key}">${label}</label>
        <textarea id="${field.key}"
            maxlength="200"
            name="${field.key}"
            placeholder="${field.placeHolder}"
            data-error-message="${field.defaultErrorMessage}"
            data-error-container="${errorMessageTargetClass}"
            ${field.readOnly ? ` readonly` : ``}
            ${field.isRequired ? ` required` : ``}
        >${value || ""}</textarea>
    `;
};

export const buildTextareaInput = (field, errorMessageTargetClass) => {
    return field.options
        ? field.options
              .map(option =>
                  createMarkup(option, field, errorMessageTargetClass)
              )
              .join("")
        : createMarkup({}, field, errorMessageTargetClass);
};
