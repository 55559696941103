import { onReady } from "~/foundation/Events/onReady";
import { audioBar } from "./markup/audioBar";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { appendElement } from "~/foundation/Dom/elementManipulation";
import { createElement } from "~/foundation/Dom/createElement";
import { pauseIcon, playIcon } from "./icons/icons";
import {
    breakpointMin,
    onWindowResize,
} from "~/foundation/Events/onWindowResize";
import { isRtl } from "~/foundation/Helpers/isRtl";

export class AudioPlayer {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined,
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.audioBarElement = this.dom.container.querySelector(
            ".article-audioplayer__bar"
        );
        this.dom.audioPlayer = this.dom.container.querySelector(
            ".article-audioplayer__audio"
        );
        this.isPlaying = false;
        onReady(() => this.initialize());
    }

    // Toggle audio bar visibility and create the audio bar element
    toggleAudioBar() {
        const audioUrl = this.dom.container.getAttribute("data-audio");

        if (!this.dom.audioBarElement) {
            this.isPlaying = true;
            this.dom.audioBarElement = createElement("section", {
                className: "article-audioplayer__bar",
                html: audioBar({
                    duration: this.formatTime(0),
                    currentTime: this.formatTime(0),
                    audioFileUrl: audioUrl,
                    isPlaying: this.isPlaying,
                }),
            });
            // Append audio bar element to the container
            appendElement(this.dom.audioBarElement, this.dom.container);

            // Add event listeners for time updates and metadata loading
            this.dom.audioPlayer = this.dom.container.querySelector(
                ".article-audioplayer__audio"
            );

            //Manually start playing since autoplay not working on android
            this.dom.audioPlayer.play();

            addEvent(this.dom.audioPlayer, "timeupdate", () =>
                this.updateAudioProgress()
            );
            addEvent(this.dom.audioPlayer, "loadedmetadata", () =>
                this.updateAudioDuration()
            );

            // Add event listener to play/pause button inside the audio bar
            const playPauseButton = this.dom.audioBarElement.querySelector(
                ".article-audioplayer__play-pause"
            );
            addEvent(playPauseButton, "click", () => this.toggleAudioPlayer());

            this.updateStartButtonText();
        } else {
            // Remove the audio bar element if it already exists
            this.dom.container.removeChild(this.dom.audioBarElement);
            this.dom.audioBarElement = null;
            this.updateStartButtonText();
        }
    }

    // Toggle audio play/pause state
    toggleAudioPlayer() {
        if (this.dom.audioPlayer.paused) {
            this.dom.audioPlayer.play();
            this.isPlaying = true;
        } else {
            this.dom.audioPlayer.pause();
            this.isPlaying = false;
        }
        this.updatePlayPauseButton();
    }

    updatePlayPauseButton() {
        const playPauseButton = this.dom.audioBarElement.querySelector(
            ".article-audioplayer__play-pause"
        );
        if (playPauseButton) {
            playPauseButton.innerHTML = this.isPlaying ? pauseIcon : playIcon;
            playPauseButton.setAttribute(
                "aria-label",
                this.isPlaying ? "Pause audio" : "Play audio"
            );
        }
    }

    // Update the audio progress bar + time displays
    updateAudioProgress() {
        if (!this.dom.audioBarElement) {
            return;
        }

        const progressElement = this.dom.audioBarElement.querySelector(
            ".article-audioplayer__time-current"
        );
        const remainingTimeElement = this.dom.audioBarElement.querySelector(
            ".article-audioplayer__time-remaining"
        );
        const progressBarElement = this.dom.audioBarElement.querySelector(
            ".article-audioplayer__progressbar"
        );

        if (
            progressElement &&
            progressBarElement &&
            remainingTimeElement &&
            this.dom.audioPlayer
        ) {
            const currentTime = this.dom.audioPlayer.currentTime;
            const duration = this.dom.audioPlayer.duration;

            progressElement.textContent = this.formatTime(currentTime);

            const percentage =
                duration > 0 ? (currentTime / duration) * 100 : 0;
            progressBarElement.style.width = `${percentage}%`;

            const playPauseButton = this.dom.audioBarElement.querySelector(
                ".article-audioplayer__play-pause"
            );

            if (playPauseButton) {
                const percentage = (currentTime / duration) * 100;
                isRtl
                    ? (playPauseButton.style.right = `${percentage}%`)
                    : (playPauseButton.style.left = `${percentage}%`);

                isRtl
                    ? (playPauseButton.style.transform = `translateX(${percentage}%)`)
                    : (playPauseButton.style.transform = `translateX(-${percentage}%)`);
            }

            const remainingTime = duration - currentTime;
            remainingTimeElement.textContent = `-${this.formatTime(
                remainingTime
            )}`;
        }
    }

    // Update the duration display after metadata is loaded
    updateAudioDuration() {
        if (this.dom.audioPlayer) {
            const duration = this.dom.audioPlayer.duration;
            const remainingTimeElement = this.dom.audioBarElement.querySelector(
                ".article-audioplayer__time-remaining"
            );
            if (remainingTimeElement) {
                remainingTimeElement.textContent = `-${this.formatTime(
                    duration
                )}`;
            }
        }
    }

    // Format from seconds to "MM:SS" format
    formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${
            remainingSeconds < 10 ? "0" : ""
        }${remainingSeconds}`;
    }

    updateStartButtonText() {
        const startButton = this.dom.container.querySelector(
            ".article-audioplayer__start"
        );

        if (startButton) {
            if (
                !this.dom.audioBarElement &&
                startButton.classList.contains("hovered")
            ) {
                startButton.classList.remove("hovered");
            }

            startButton.setAttribute(
                "aria-label",
                this.dom.audioBarElement ? "Close Audio Bar" : "Open Audio Bar"
            );

            if (this.dom.audioBarElement && breakpointMin("md")) {
                startButton.classList.add("hide-text");
            } else if (!this.dom.audioBarElement) {
                startButton.classList.remove("hide-text");
            }

            const addHoverClass = () => {
                if (this.dom.audioBarElement) {
                    startButton.classList.add("hovered");
                }
            };

            const removeHoverClass = () => {
                if (this.dom.audioBarElement) {
                    startButton.classList.remove("hovered");
                }
            };

            // Add event listeners if the bar is open
            if (this.dom.audioBarElement) {
                addEvent(startButton, "mouseenter", addHoverClass);
                addEvent(startButton, "mouseleave", removeHoverClass);
            }
        }
    }

    kill() {
        removeAllEvents(this.dom.startButton);
        removeAllEvents(this.dom.audioPlayer);
    }

    // Init audio player
    initialize() {
        this.dom.audioStart = this.dom.container.querySelector(
            ".article-audioplayer__start"
        );

        addEvent(this.dom.audioStart, "click", () => {
            this.toggleAudioBar();
        });

        // Update the start button text on window resize
        onWindowResize(() => {
            this.updateStartButtonText();
        });
    }
}
