export const pauseIcon = `
    <svg width="15" height="15" viewBox="0 0 24 24" fill="#00A3E0" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C10 20.5523 9.55228 21 9 21C8.44771 21 8 20.5523 8 20V4C8 3.44772 8.44772 3 9 3C9.55229 3 10 3.44772 10 4L10 20ZM16 20C16 20.5523 15.5523 21 15 21C14.4477 21 14 20.5523 14 20L14 4C14 3.44772 14.4477 3 15 3C15.5523 3 16 3.44772 16 4L16 20Z"></path>
    </svg>
`;

export const playIcon = `
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M4.16233 3.04657L11.9371 7.5L4.16233 11.9534L4.16233 3.04657Z" fill="none" stroke-width="1.3" stroke="#00A3E0"></path>
    </svg>
`;
