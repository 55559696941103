export const screensizes = {
    "xxs": "1px",
    "xs-i5": "320px",
    "xs-i5-h": "569px",
    "xs-i6": "375px",
    "xs-i6-h": "700px",
    "xs": "480px",
    "sm": "768px",
    "md": "992px",
    "lg": "1366px",
    "xl": "1920px"
}