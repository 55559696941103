/**
 *
 * @module utils/isExperienceEditor
 * @author Jimmi Nielsen <jimmi.nielsen@akqa.com>
 *
 * @example
 * import { isExperienceEditor } from './utils/helpers/isExperienceEditor';
 * if (isExperienceEditor {
 *     // code here...
 * }
 *
 */

/**
 *
 * @type {Boolean}
 */
export const isExperienceEditor =
    document.body.classList.contains("pagemode-edit") ||
    document.body.classList.contains("pagemode-preview");
