import { parseHTML } from "~/foundation/Dom/elementManipulation";
import closeSvg from "~/foundation/Icons/close";

export const pollLoginMarkup = (dataObject, hideCloseIcon = false) => {
    return parseHTML(`
        ${!hideCloseIcon && `<div class="poll__close">${closeSvg}</div>`}
        <div class="poll__login poll__content">
            <h2 class="poll__error margin__bottom--sm">
                ${dataObject.needsLoggedInLabel}
            </h2>
            <button type="button" class="button button--primary"><span>${
                dataObject.registerLogInLabel
            }</span></button>
        </div>
    `);
};
