const aramcoLifePale = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" width="95" height="57" viewBox="0 0 95 57">
<defs>
    <filter id="prefix__a">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
    </filter>
    <path id="prefix__b" d="M0 55L93 55 93 0 0 0z"/>
</defs>
<g fill="none" fill-rule="evenodd" filter="url(#prefix__a)">
    <g transform="translate(1 1)">
        <path fill="#323232" d="M7.517 48.164H23V55H5.79C2.001 55 0 53.062 0 49.395V21h7.517v27.164z"/>
        <path fill="#323232" d="M28 55L36 55 36 21 28 21zM50.047 55H42V26.605C42 22.938 44.002 21 47.79 21H65v6.836H50.047v6.9h13.375v6.836H50.047V55zM93 55H75.79C72.002 55 70 53.062 70 49.395V21h23v6.836H78.047v6.746h13.375v6.76H78.047v6.822H93V55zM87.02 10.814c-2.13 0-3.826-1.73-3.826-3.814s1.696-3.775 3.826-3.775c2.09 0 3.785 1.69 3.785 3.775 0 2.084-1.695 3.814-3.785 3.814M87.02 0C83.155 0 80 3.146 80 7s3.155 7 7.02 7C90.884 14 94 10.854 94 7s-3.116-7-6.98-7M58.775 0c-1.543 0-3.127.317-4.393.833C53.352.238 52.362 0 50.937 0c-1.781 0-3.601.238-5.58.912l-.357.12V14h3.523V3.371c.713-.238 1.266-.317 1.979-.317 1.742 0 2.256.555 2.256 2.379V14h3.563V5.195c0-.793-.04-1.309-.158-1.784.712-.238 1.266-.357 1.9-.357 1.662 0 2.414.753 2.414 2.379V14H64V5.156c0-1.745-.277-2.776-1.068-3.649C62.06.555 60.596 0 58.775 0M73.885 10.981c-2.235 0-3.516-1.434-3.516-3.941 0-2.565 1.296-4.039 3.55-4.039.467 0 .862.051 1.24.168 0 0 .691.204 1.3.698l1.42-2.518C77.106.682 76.195.394 75.886.312 75.016.04 73.738 0 73.738 0 69.706 0 67 2.892 67 7.192c0 4.136 2.566 6.806 6.546 6.806 0 0 2.665.121 4.454-1.594l-1.67-2.218c-.904.596-1.533.795-2.445.795M24.44 0C19.36 0 17 2.24 17 7v7h3.64V6.96c0-2.4 1.36-3.88 3.48-3.88.88 0 1.56.16 2.04.28L27 .2c-1.41-.201-2.56-.2-2.56-.2M37.656 7.02c0-2.672-.977-3.988-2.93-3.988-1.992 0-3.27 1.555-3.27 3.908 0 2.832 1.278 4.069 4.134 4.069h2.066V7.02zM41 6.621V14h-4.584c-3.156 0-4.546-.24-6.2-1.396C28.79 11.567 28 9.613 28 7.06 28 3.55 30.18 0 34.989 0 38.896 0 41 2.314 41 6.621zM10.534 7.02c0-2.712-.905-3.988-2.902-3.988S4.354 4.587 4.354 6.94c0 2.832 1.281 4.069 4.145 4.069h2.035V7.02zM14 6.621V14H9.44c-3.165 0-4.559-.24-6.217-1.396C1.791 11.567 1 9.613 1 7.06 1 3.55 3.185 0 8.009 0 11.928 0 14 2.314 14 6.621z" mask="url(#prefix__c)"/>
    </g>
</g>
</svg>

`;

export default aramcoLifePale;
