import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { arSA } from "date-fns/locale";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { isIE11 } from "~/foundation/Helpers/isIE11";

// eslint-disable-next-line no-unused-vars
export const searchResultsMarkup = (dataObject, isLoggedIn = false) => {
    const formatOptions = {};
    // eslint-disable-next-line no-unused-vars
    const locale = isRtl ? (formatOptions.locale = arSA) : "";
    const imageMarkup = `<img alt="A lazy image" class="lazy" ${
        isIE11 && `src="${dataObject.imageUrl}"`
    } data-src="${dataObject.imageUrl}" />`;
    const videoImageMarkup = `<img alt="video" class="lazy" data-src="${
        dataObject.imageUrl === "" || !dataObject.imageUrl
            ? dataObject.thumbnailUrl
            : dataObject.imageUrl
    }">
                <noscript>
                    <img src="${
                        dataObject.imageUrl === "" || !dataObject.imageUrl
                            ? dataObject.thumbnailUrl
                            : dataObject.imageUrl
                    }" alt="video">
                </noscript>
                <span class="play">
                    <svg focusable="false" width="28px" height="20px" viewBox="0 0 28 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <polygon points="0 0 35.483871 0 35.483871 24.9999798 0 24.9999798"></polygon>
                        </defs>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-859.000000, -3932.000000)">
                                <g transform="translate(855.000000, 3930.000000)">
                                    <mask fill="white">
                                        <use xlink:href="#path-1"></use>
                                    </mask>
                                    <path d="M15.1363636,16.2208065 L15.1363636,7.77919355 L22.4545455,12.0001613 L15.1363636,16.2208065 Z M31.4148636,5.12306452 C31.0928636,3.89370968 30.1440455,2.92564516 28.9394091,2.59709677 C26.7558864,2 18,2 18,2 C18,2 9.24411364,2 7.06059091,2.59709677 C5.85595455,2.92564516 4.90713636,3.89370968 4.58513636,5.12306452 C4,7.35112903 4,12 4,12 C4,12 4,16.6487097 4.58513636,18.8769355 C4.90713636,20.1062903 5.85595455,21.0743548 7.06059091,21.4030645 C9.24411364,22 18,22 18,22 C18,22 26.7558864,22 28.9394091,21.4030645 C30.1440455,21.0743548 31.0928636,20.1062903 31.4148636,18.8769355 C32,16.6487097 32,12 32,12 C32,12 32,7.35112903 31.4148636,5.12306452 L31.4148636,5.12306452 Z" id="Fill-1" fill="#FFFFFF" mask="url(#mask-2)"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
        `;
    const conditionalImageMarkup = dataObject.hasVideo
        ? videoImageMarkup
        : imageMarkup;

    return parseHTML(`
    <div class="article-teaser article-teaser--small">
        <a href="${dataObject.url}">
            <div class="article-teaser__thumbnail article-teaser__thumbnail--small inview">

            ${conditionalImageMarkup}

            </div>
            <div class="article-teaser__content">
                <div class="article-teaser__content-wrapper">
                    <ul class="article-list inview">
                        <li class="article-list__item">
                            ${dataObject.formattedDate}
                        </li>
                    </ul>
                    <div class="article-teaser__text-wrapper align-center">
                        <h3 class="title inview">
                            ${dataObject.headline}
                        </h3>
                    </div>
                </div>
            </div>
        </a>
</div>`);
};
