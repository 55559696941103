import { buildTextInput } from "../inputs/buildTextInput";

const stepThreeTemplate = ({ classes, sections }) => {
    const errorMessageStepThree = `${classes.rootClass}__error-message`;

    return `
        <div class="${classes.rootClass}__header">
            <h2 class="${classes.rootClass}__title">${sections.title}</h2>
            <p class="${classes.rootClass}__manchet">${sections.description}</p>
        </div>
        <div class="${classes.rootClass}__section">
            <div class="${classes.rootClass}__preview-container"></div>
            <fieldset class="${classes.rootClass}__fieldset">
                <div class="${classes.rootClass}__ghost-input">

            ${sections.fields
                .map(field => `${buildTextInput(field, classes)}`)
                .join("")}
                    <p class="${errorMessageStepThree}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepThreeTemplate;
