/**
 *
 * @module utils/isRtl
 * @author Peter Krogh <peter.krogh@akqa.com>
 *
 * @example
 * import { isRtl } from './utils/helpers/isRtl';
 * if (isRtl {
 *     // code here...
 * }
 *
 */

/**
 *
 * @type {Boolean}
 */
export const isRtl =
    document.querySelector("html").getAttribute("dir") === "rtl";
