import { onReady } from "~/foundation/Events/onReady";
import Flickity from "flickity";

export class VideoEpisodesUpcoming {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.episodeSlider = this.dom.container.querySelector(
            ".video-episodes-upcoming__carousel"
        );
        this.dom.episodeSliderElements =
            this.dom.episodeSlider.querySelectorAll(".gallery-cell");

        onReady(() => this.initialize());
    }

    multipleElementsLayout = () => {
        return this.dom.episodeSliderElements.length > 1;
    };

    initialize() {
        const episodeFlickity = {
            pageDots: this.multipleElementsLayout(),
            prevNextButtons: false,
            freeScroll: true,
            wrapAround: this.dom.episodeSliderElements.length > 3,
            cellAlign: "left",
            lazyLoad: 3,
            on: {
                dragStart: () => {
                    this.dom.container.classList.add("translating");
                },
                dragEnd: () => {
                    this.dom.container.classList.remove("translating");
                },
                settle: () => {
                    this.dom.container.classList.remove("translating");
                }
            }
        };

        // eslint-disable-next-line no-unused-vars
        const episodeSlider = new Flickity(
            this.dom.episodeSlider,
            episodeFlickity
        );
    }
}
