import { buildRadioIconInput } from "~/feature/GreetingCard/greeting/inputs/buildRadioIconInput";

const stepOneTemplate = ({ classes, sections, texts, iconRadioButtonOptions }) => {
    const errorMessageTarget = `${classes.rootClass}__error-message`;

    return `
        <div class="${classes.rootClass}__header">
            <h2 class="${classes.rootClass}__title">${sections.title}</h2>
            <p class="${classes.rootClass}__manchet">${sections.description}</p>
        </div>
        <div class="${
            classes.cardClass
        }__slider js-flickity" data-flickity-container>
            ${buildRadioIconInput(
                sections.fields[0],
                texts,
                classes,
                errorMessageTarget,
                true,
                iconRadioButtonOptions
            )}
            <p class="${errorMessageTarget}"></p>
        </div>
    `;
};

export default stepOneTemplate;
