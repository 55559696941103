import { onReady } from "~/foundation/Events/onReady";
import anime from "animejs";
import LazyLoad from "vanilla-lazyload";
import {
    currentWindowWidth,
    currentWindowHeight
} from "~/foundation/Events/onWindowResize";

export class CountDown {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        onReady(() => this.initialize());
    }

    loadVideo() {
        const lazyLoadClass =
            currentWindowWidth > currentWindowHeight
                ? ".lazy-video-landscape"
                : ".lazy-video-portrait";

        return new Promise((resolve, reject) => {
            const lazyloadConfig = {
                elements_selector: lazyLoadClass,
                class_loading: "lz-loading",
                class_loaded: "lz-loaded",
                callback_loaded: el => {
                    resolve(el);
                    this.videoLoaded = true;
                    this.lazyloadInstance.destroy();
                },
                callback_error: el => {
                    reject(el);
                    this.lazyloadInstance.destroy();
                }
            };

            this.lazyloadInstance = new LazyLoad(lazyloadConfig);
            if (this.lazyloadInstance.toLoadCount === 0) {
                resolve();
            }
        });
    }

    initCountDown = () => {
        //Check for arabic in browser to redirect arabic users to their language
        const lang = navigator.language || navigator.userLanguage;
        const isoLang = lang.substring(0, 2);

        if (isoLang === "ar") {
            const redirectUrl = window.location.origin + "/ar";
            window.location.href = redirectUrl;
        }

        const launchTime = this.dom.container
            .querySelector(".countdown__timer")
            .getAttribute("data-launchtime");
        const digitsHours = this.dom.container.querySelector(".digits__hours");
        const digitsMins = this.dom.container.querySelector(".digits__mins");
        const digitsSecs = this.dom.container.querySelector(".digits__secs");

        // set the End Date
        var endDate = new Date(launchTime).getTime();

        const second = 1000,
            minute = second * 60,
            hour = minute * 60;

        setInterval(function () {
            // Convert the date to UTC as endDate from backend
            const utcDate = new Date().toUTCString();
            let now = new Date(utcDate).getTime();
            let timeLeft = endDate - now;

            if (timeLeft > 0) {
                let hours = Math.floor(timeLeft / hour);
                let minutes = Math.floor((timeLeft % hour) / minute);
                let seconds = Math.floor((timeLeft % minute) / second);

                document.getElementById("countdown-hours").innerHTML = (
                    "0" + hours
                ).slice(hours > 99 ? -3 : -2);

                document.getElementById("countdown-mins").innerHTML = (
                    "0" + minutes
                ).slice(-2);

                document.getElementById("countdown-secs").innerHTML = (
                    "0" + seconds
                ).slice(-2);

                var animeConfigs = {};
                animeConfigs.opacityIn = [0, 1];
                animeConfigs.scaleIn = [0.5, 1];
                animeConfigs.scaleOut = 1.1;
                animeConfigs.durationIn = 500;
                animeConfigs.durationOut = 500;
                animeConfigs.delay = 500;

                anime
                    .timeline({ loop: true })
                    .add({
                        targets: digitsSecs,
                        opacity: animeConfigs.opacityIn,
                        scale: animeConfigs.scaleIn,
                        duration: animeConfigs.durationIn
                    })
                    .add({
                        targets: digitsSecs,
                        opacity: 0,
                        scale: animeConfigs.scaleOut,
                        duration: animeConfigs.durationOut,
                        easing: "easeInExpo"
                    });

                if (seconds === 0) {
                    anime
                        .timeline()
                        .add({
                            targets: digitsMins,
                            opacity: 0,
                            scale: animeConfigs.scaleOut,
                            duration: animeConfigs.durationOut,
                            easing: "easeInExpo",
                            delay: animeConfigs.delay
                        })
                        .add({
                            targets: digitsMins,
                            opacity: animeConfigs.opacityIn,
                            scale: animeConfigs.scaleIn,
                            duration: animeConfigs.durationIn
                        });
                }

                if (minutes === 0 && seconds === 0) {
                    anime
                        .timeline()
                        .add({
                            targets: digitsHours,
                            opacity: 0,
                            scale: animeConfigs.scaleOut,
                            duration: animeConfigs.durationOut,
                            easing: "easeInExpo",
                            delay: animeConfigs.delay
                        })
                        .add({
                            targets: digitsHours,
                            opacity: animeConfigs.opacityIn,
                            scale: animeConfigs.scaleIn,
                            duration: animeConfigs.durationIn
                        });
                }
            } else {
                window.location.reload();
            }
        }, 1000);
    };

    initialize() {
        this.loadVideo().then(() => this.initCountDown());
    }
}
