import { onReady } from "~/foundation/Events/onReady";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { Share } from "~/feature/Article/share/share";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
import { SLOW } from "~/foundation/Constants/durations";
import { fetcher } from "~/foundation/Api";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { LIKE_SAVE, LIKE_REMOVE } from "~/foundation/Constants/api-end-points";
import { isMobile } from "~/foundation/Helpers/isMobile";
import {
    onClickOutside,
    removeOnClickOutside
} from "~/foundation/Events/onClickOutside";

const activeClasses = {
    comments: "comments-active",
    share: "share-active",
    liked: "like-button--liked"
};

export class SocialBar {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.commentsDialogOpen = false;
        this.shareOpen = false;
        this.forceClose = false;
        this.shareDialog = undefined;
        this.commentsDialog = undefined;
        this.articleId = undefined;
        this.replyMessageShown = false;

        onReady(() => this.initialize());
    }

    kill() {
        removeAllEvents(this.dom.commentDialogButton);
        removeAllEvents(this.dom.shareDialogButton);
    }

    toggleCommentsComponent(close = false) {
        let closeCommentDialog = close || this.forceClose;

        if (!closeCommentDialog) {
            this.commentsDialogOpen = true;
            this.dom.socialBar.classList.add(activeClasses.comments);
            // Lazyload comments component
            import(
                /* webpackChunkName: "Comments component" */ "~/feature/Article/comments/comments"
            ).then(module => {
                this.commentsDialog = new module.Comments({
                    domReference: this.dom.commentDialog,
                    articleId: this.articleId,
                    showSnackMessage: !this.replyMessageShown,
                    snackMessage: "Please use a friendly tone",
                    onClose: () => {
                        if (!this.commentsDialog) return;
                        this.replyMessageShown = true;
                        this.commentsDialog && this.commentsDialog.kill();
                        this.commentsDialog = undefined;
                        this.commentsDialogOpen = false;
                        this.dom.socialBar.classList.remove(
                            activeClasses.comments
                        );
                    }
                });
            });
            const closeCommentButton =
                this.dom.container.querySelector(".close-dialog");

            addEvent(closeCommentButton, "click", this.toggleCommentDialog);

            onClickOutside(
                this.dom.commentDialog,
                ({ target }) => {
                    if (target) {
                        this.toggleCommentDialog();
                    }
                },
                window,
                false
            );
        } else {
            this.commentsDialog.toggleCommentsDialogAnimation(true);
            this.dom.socialBar.classList.remove(activeClasses.share);
            this.dom.socialBar.classList.remove(activeClasses.comments);
        }
    }

    toggleCommentDialog = () => {
        anime.remove(this.dom.shareDialogTitle);

        if (this.commentsDialogOpen || this.forceClose) {
            // If comments dialog is open - close it.
            // returns finished promise when animation is done
            removeOnClickOutside(this.dom.commentDialog);
            return this.commentsDialog?.toggleCommentsDialogAnimation(true);
        } else if (!this.commentsDialogOpen && !this.shareOpen) {
            // If either comments or share dialog is open - open comments dialog
            this.toggleCommentsComponent();
        } else {
            this.forceClose = true;
            // Wait for share has closed before opening comments
            this.toggleShareDialog().finished.then(() => {
                this.toggleCommentsComponent();
            });
        }

        this.forceClose = false;
    };

    toggleShareDialogAnimation(close = false) {
        const closeShareDialog = close || this.forceClose;

        if (!closeShareDialog) {
            this.shareOpen = true;
            this.dom.socialBar.classList.add(activeClasses.share);
            this.dom.shareDialog.style.pointerEvents = "auto";
        } else {
            this.shareOpen = false;
            this.dom.socialBar.classList.remove(activeClasses.share);
            this.dom.socialBar.classList.remove(activeClasses.comments);
        }

        const timeline = anime
            .timeline({
                begin: () => {
                    if (!closeShareDialog) {
                        this.dom.shareDialog.style.display = "flex";
                        this.shareDialog = new Share(this.dom.shareDialog);
                    } else {
                        this.shareDialog && this.shareDialog.kill();
                        this.dom.shareDialog.style.pointerEvents = "none";
                    }
                },
                complete: () => {
                    if (closeShareDialog) {
                        this.dom.shareDialog.style.display = "none";
                        this.shareDialog = undefined;
                    }
                }
            })
            .add({
                targets: this.dom.shareDialog,
                opacity: [0, 1],
                translateY: !closeShareDialog ? ["100%", "0%"] : ["0%", "100%"],
                duration: SLOW,
                easing: STANDARDCUBICBEZIER
            });

        if (!closeShareDialog) {
            timeline.add({
                targets: [
                    this.dom.shareDialogTitle,
                    this.dom.shareDialogInner?.children
                ],
                opacity: !closeShareDialog ? [0, 1] : [1, 0],
                translateY: !closeShareDialog ? [20, 0] : [0, 20],
                delay: anime.stagger(100)
            });
        }

        return timeline;
    }

    openWebShareDialog() {
        anime.remove(this.dom.shareDialogTitle);
        anime.remove(this.dom.shareDialogInner.children);

        if (this.shareOpen || this.forceClose) {
            // returns finished promise when animation is done
            return this.toggleShareDialogAnimation(true);
        } else if (!this.shareOpen && !this.commentsDialogOpen) {
            this.toggleShareDialogAnimation();
        } else {
            this.forceClose = true;
            // Wait for comment has closed before opening share
            this.toggleCommentDialog().finished.then(() => {
                this.toggleShareDialogAnimation();
            });
        }

        this.forceClose = false;
    }

    toggleShareDialog = () => {
        if (!isMobile()) {
            return this.openWebShareDialog();
        } else {
            if (navigator.share) {
                const description = document.head.querySelector(
                    "meta[name=description]"
                );

                const title = document.title;

                const shareData = {
                    url: window.location.href
                };

                if (description) {
                    shareData.text = description.content;
                }

                if (title) {
                    shareData.title = title;
                }

                navigator.share(shareData);
            } else {
                this.openWebShareDialog();
            }
        }
    };

    toggleLike = e => {
        const likeButton = e.target;
        const isLiked = likeButton.classList.contains(activeClasses.liked);
        const url = isLiked ? LIKE_REMOVE : LIKE_SAVE;
        const likes = parseInt(this.dom.likesCount.textContent);

        fetcher(`/${isRtl ? "ar" : "en"}${url}`, "POST", {
            ArticleId: this.articleId
        }).then(({ success }) => {
            if (success && isLiked) {
                likeButton.classList.remove(activeClasses.liked);
                this.dom.likesCount.textContent = likes - 1;
            } else if (success && !isLiked) {
                const activeLikeButton = likeButton.querySelector(".active");
                likeButton.classList.add(activeClasses.liked);
                this.dom.likesCount.textContent = likes + 1;
                anime({
                    targets: activeLikeButton,
                    easing: STANDARDCUBICBEZIER,
                    duration: SLOW,
                    keyframes: [{ scale: 1.25 }, { scale: 1 }]
                });
            }
        });
    };

    initialize() {
        this.articleId = this.dom.container.getAttribute("data-article-id");

        this.dom.commentDialog =
            this.dom.container.querySelector(".comments-dialog");
        this.dom.socialBar = this.dom.container.querySelector(".social-bar");
        this.dom.commentDialogButton = this.dom.container.querySelector(
            ".comment-dialog-button"
        );
        this.dom.commentsCount = this.dom.container.querySelector(
            ".comment-dialog-button__count"
        );

        this.dom.likeButton = this.dom.container.querySelector(".like-button");
        this.dom.likesCount = this.dom.container.querySelector(
            ".like-button__count"
        );

        this.dom.shareDialogButton =
            this.dom.container.querySelector(".share-button");

        this.dom.shareDialog =
            this.dom.container.querySelector(".share-dialog");

        this.dom.shareDialogTitle = this.dom.container.querySelector(
            ".share-dialog__title"
        );

        this.dom.shareDialogInner = this.dom.container.querySelector(
            ".share-dialog__social"
        );

        const closeCommentButton =
            this.dom.container.querySelector(".close-dialog");

        addEvent(closeCommentButton, "click", this.toggleCommentDialog);

        addEvent(
            this.dom.commentDialogButton,
            "click",
            this.toggleCommentDialog
        );

        if (this.dom.likeButton) {
            addEvent(this.dom.likeButton, "click", this.toggleLike);
        }

        if (this.dom.shareDialogButton) {
            addEvent(
                this.dom.shareDialogButton,
                "click",
                this.toggleShareDialog
            );
        }
    }
}
