import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { forEach } from "~/foundation/Helpers/forEach";

export const searchConfigurationMarkup = (
    dataObject,
    itemType,
    dictionaryData
) => {
    let searchTopics = "";
    const dateTimeCalcs = {
        now: new Date().getTime(),
        pastDay: new Date().getTime() - 24 * 60 * 60 * 1000,
        pastWeek: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        pastMonth: new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
        pastYear: new Date().getTime() - 365 * 24 * 60 * 60 * 1000
    };

    const datePresetMarkup = `
        <div class="search__date-preset search__topic--checked" data-time-from="" data-time-to="">${dictionaryData.anyTime}</div>
        <div class="search__date-preset" data-time-from="${dateTimeCalcs.pastDay}" data-time-to="${dateTimeCalcs.now}">${dictionaryData.pastDay}</div>
        <div class="search__date-preset" data-time-from="${dateTimeCalcs.pastWeek}" data-time-to="${dateTimeCalcs.now}">${dictionaryData.pastWeek}</div>
        <div class="search__date-preset" data-time-from="${dateTimeCalcs.pastMonth}" data-time-to="${dateTimeCalcs.now}">${dictionaryData.pastMonth}</div>
        <div class="search__date-preset" data-time-from="${dateTimeCalcs.pastYear}" data-time-to="${dateTimeCalcs.now}">${dictionaryData.pastYear}</div>`;

    forEach(dataObject.data, sitecoreItem => {
        searchTopics += `<div class="search__topic" data-item-id="${sitecoreItem.itemID}">${sitecoreItem.name}<span class="search__topic-count"></span></div>`;
    });

    return parseHTML(`
        <div class="search__category" data-item-list="${itemType}">
            ${dataObject.localizedString}
            <span class="search__category-count"></span>
            <div class="search__category-icon">
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" width="9" height="15" viewBox="0 0 9 15">
                    <path d="M7.501 0l1.072 1.069L2.26 7.364l6.463 6.445-1.072 1.069L1.19 8.432l-.117.117L0 7.481 7.501 0z" />
                </svg>
            </div>
            <div class="search__category-content-holder visuallyhidden">
            ${
                dataObject.data
                    ? searchTopics
                    : `<div class="search__date-presets">${datePresetMarkup}</div>`
            }
            </div>
        </div>
    `);
};
