import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";
import { addClass, removeClass } from "~/foundation/Dom/classList";
import {
    bootstrapConstructorOnElement,
    bootstrappedInstances
} from "~/foundation/Bootstrapper";
import { Search } from "~/foundation/Components/search/search";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
import { MEDIUM } from "~/foundation/Constants/durations";
import { scrollTo } from "~/foundation/Dom/scrollTo";
import { disableScrollLock, enableScrollLock } from "~/foundation/Dom/scrollLock";
import { onScroll } from "~/foundation/Events/onScroll";
import { setupInView } from "~/foundation/Dom/inView";

export class SearchModal {
    /**
     * Internal placeholder for cached DOM-objects and settings objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};
    settings = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> lists-load-more
     */
    constructor(domReference) {
        this.dom.container = domReference;

        // This is ugly(!) but since barba initiates our data modules on markup not applied to the dom we have to rely on that domReference used in the constructor.
        this.dom.mainContainer = this.dom.container.closest("main.main");

        this.dom.searchModal =
            this.dom.mainContainer.querySelector(".search__modal");

        this.dom.closeButton = this.dom.searchModal?.querySelector(
            ".search__modal-close-button"
        );

        this.dom.innerWrapper = this.dom.searchModal?.querySelector(
            ".search__modal-inner-wrapper"
        );

        this.settings = {
            openSearchClass: "search__modal--open",
            openSearchClassOnBody: "search-open"
        };

        onReady(() => this.initialize());
    }

    /**
     * Bootstrapping the search module
     */
    bootstrapSearchModule = () => {
        bootstrapConstructorOnElement(
            document.querySelector("[data-module='search']"),
            Search,
            "search"
        );
    };

    /**
     * Opening search by bootstrapping if necessary, adding the class and animating in the overlay
     */
    openSearch = () => {
        if (!bootstrappedInstances["search"]) {
            this.bootstrapSearchModule();
        }

        anime
            .timeline({
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM,
                begin: () => {
                    // Scrolling to top
                    void scrollTo(0, 200);

                    // Adding the open class
                    addClass(
                        this.dom.searchModal,
                        this.settings.openSearchClass
                    );
                },
                complete: () => {
                    // Locking the scroll
                    enableScrollLock();

                    // Cursor in the input
                    document.querySelector(".search__input input").focus();
                }
            })
            .add({
                targets: this.dom.searchModal,
                opacity: 1,
                delay: 200
            });
    };

    /**
     * Closing search overlay with classes and animation
     */
    closeSearch = () => {
        anime
            .timeline({
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM,
                begin: () => {
                    // Unlocking the scroll
                    disableScrollLock();
                },
                complete: () => {
                    // Removing the open class
                    removeClass(
                        this.dom.searchModal,
                        this.settings.openSearchClass
                    );
                }
            })
            .add({
                targets: this.dom.searchModal,
                opacity: 0
            });
    };

    initialize() {
        addEvent(this.dom.container, "click", () => {
            this.openSearch();
        });

        addEvent(this.dom.closeButton, "click", () => {
            this.closeSearch();
        });

        // Since the inView is detecting the scroll on window we have to detect scroll inside the modal itself
        onScroll(
            this.dom.innerWrapper,
            () => {
                // Initiating the lazyload and inview within our scope after we appended the data
                const inViewClass = ".inview";
                const inViewElements =
                    this.dom.innerWrapper.querySelector(inViewClass);

                if (inViewElements) {
                    setupInView(inViewClass, "inview--active", "show", 0);
                }
            },
            200
        );
    }
}
