import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../close";

export const enterEmailMarkup = labels => {
    const { headerTitle, emailLabel, continueLabel } = labels;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--signup-email">
        <div class="animate-translate input">
            <input autocomplete="off" required type="text" id="email" name="email" />
            <label for="email">${emailLabel}</label>
            <span class="error"></span>
        </div>
    </div>
    <div class="membership-footer membership-footer--signup-email">
        <button class="animate-scale--out button button--primary" type="submit">
            <span>${continueLabel}</span>
        </button>
        <div class="signup-pagination">
            <button id="1" class="signup-pagination__item"></button>
            <button id="2" class="signup-pagination__item signup-pagination__item--current"></button>
            <button id="3" class="signup-pagination__item"></button>
        </div>
    </div>`);
};
