import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { fetcher } from "~/foundation/Api";
import { forEach } from "~/foundation/Helpers/forEach";

export class Newsletter {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.subscriptionLists = config.subscriptionLists;

        this.subscribeUserApiUrl = config.subscribeUserApiUrl;
        this.unsubscribeUserApiUrl = config.unsubscribeUserApiUrl;

        this.initialize();
    }

    toggleSubscription = event => {
        const checkbox = event.target;

        if (checkbox === this.dom.consentCheckbox) {
            if (checkbox.checked === false) {
                const checkboxes = this.dom.inputCheckboxes;
                forEach(checkboxes, checkbox => {
                    if (checkbox.checked) {
                        checkbox.checked = false;
                        this.unsubscribe(checkbox, this.isLastSubscription());
                    }
                });
            }
            return;
        }

        if (checkbox.checked) {
            this.subscribe(checkbox);
        } else {
            this.unsubscribe(checkbox, this.isLastSubscription());
        }
    };

    isLastSubscription = () => {
        const checkboxes = [...this.dom.inputCheckboxes]
            .filter(checkbox => checkbox !== this.dom.consentCheckbox)
            .filter(checkbox => checkbox?.checked);

        return checkboxes.length > 0;
    };

    subscribe = checkbox => {
        fetcher(this.subscribeUserApiUrl, "POST", {
            listId: checkbox.id,
            acceptSubscribe: true
        }).then(result => {
            if (result?.success) {
                if (!this.dom.consentCheckbox.checked) {
                    this.dom.consentCheckbox.checked = true;
                }
            } else {
                checkbox.checked = false;
            }
        });
    };

    unsubscribe = (checkbox, consent) => {
        fetcher(this.unsubscribeUserApiUrl, "POST", {
            listId: checkbox.id,
            acceptSubscribe: consent
        }).then(result => {
            if (result?.success) {
                if (!consent && this.dom.consentCheckbox.checked) {
                    this.dom.consentCheckbox.checked = false;
                }
            } else {
                checkbox.checked = true;
            }
        });
    };

    kill() {
        removeAllEvents(this.dom.checkboxes);
    }

    initialize() {
        this.dom.checkboxes =
            this.dom.membershipContent.querySelectorAll(".checkbox");
        this.dom.inputCheckboxes =
            this.dom.membershipContent.querySelectorAll(".checkbox input");
        this.dom.consentCheckbox =
            this.dom.membershipContent.querySelector("#consent");

        addEvent(this.dom.checkboxes, "change", this.toggleSubscription);
    }
}
